import { useEffect, useState } from "react";
import { MedicationBase } from "@oben-core-web/models/medication-base";
import { MedicationBaseService } from "@oben-core-web/services/medication-base-service";

const useMedicationBase = (placeBasedCareProvId: string) => {
  const [medicationBases, setMedicationBases] = useState<MedicationBase[]>([]);
  useEffect(() => {
    let loading = false;
    const fetchMedBases = async () => {
      if (!loading) {
        const medBaseService = new MedicationBaseService();
        const medBases = await medBaseService.getAllMedicationBases(
          placeBasedCareProvId
        );
        setMedicationBases(medBases);
      }
    };
    fetchMedBases();
    return () => {
      loading = true;
    };
  }, [placeBasedCareProvId]);
  return { medicationBases };
};

export default useMedicationBase;

import { VStack, Text, HStack } from "@chakra-ui/react";
import React from "react";
import NavLink from "../atoms/NavLink";
import { FiCalendar, FiHome, FiTag, FiUsers } from "react-icons/fi";
import useCurrentUser from "@/hooks/useCurrentUser";
import { UserType } from "@oben-core-web/constants/core-enums";
import ObenLogo from "@/assets/ObenLogo";

const NavSidebar = () => {
  const { currentUser } = useCurrentUser();
  return (
    <VStack>
      <HStack>
        <ObenLogo height={30} width={30} />
        <Text
          as={"h1"}
          fontSize={"larger"}
          textAlign={"center"}
          lineHeight={"3rem"}
        >
          Oben Health
        </Text>
      </HStack>
      <VStack
        w={"full"}
        h={"full"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        // px={4}
        fontSize={"large"}
      >
        <NavLink to='/' icon={FiHome} label='Home' />
        <NavLink to='/patients' icon={FiUsers} label='Patients' />
        <NavLink to='/appointments' icon={FiCalendar} label='Appointments' />
        {currentUser?.userType === UserType.ProgramManager && (
          <NavLink to='/payments' icon={FiTag} label='Payments' />
        )}
      </VStack>
    </VStack>
  );
};

export default NavSidebar;

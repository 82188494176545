import { FreqPeriod, FreqPeriodHelper } from "../constants/core-enums";

export interface IMedDosage {
  // eg. 50mg, once daily, for 28 days
  strength: number; // eg. 50 (mg)
  frequencyCount: number; // eg. 1 (time)
  frequencyPeriod: FreqPeriod; // eg. Daily
  durationCount: number; // eg. 28 (days)
}

export class MedDosage {
  strength: number;
  frequencyCount: number;
  frequencyPeriod: FreqPeriod;
  durationCount: number;

  constructor({
    strength,
    frequencyCount,
    frequencyPeriod,
    durationCount
  }: IMedDosage) {
    this.strength = strength;
    this.frequencyCount = frequencyCount;
    this.frequencyPeriod = frequencyPeriod;
    this.durationCount = durationCount;
  }

  static fromMap(data: { [key: string]: any }): MedDosage {
    return new MedDosage({
      strength: data["strength"] ?? 0,
      frequencyCount: data["frequencyCount"] ?? 0,
      frequencyPeriod:
        FreqPeriodHelper.valueOf(data["frequencyPeriod"]) ?? FreqPeriod.Unknown,
      durationCount: data["durationCount"] ?? 0
    });
  }

  toJson(): { [key: string]: any } {
    return {
      strength: Number(this.strength),
      frequencyCount: Number(this.frequencyCount),
      frequencyPeriod: this.frequencyPeriod,
      durationCount: Number(this.durationCount)
    };
  }
}

export class ASCVDCoefficients {
  readonly age: number;
  readonly ageSquared: number;
  readonly totalCholesterol: number;
  readonly totalCholesterolAge: number;
  readonly hdlCholesterol: number;
  readonly hdlCholesterolAge: number;
  readonly sbpTreated: number;
  readonly sbpUntreated: number;
  readonly smoking: number;
  readonly smokingAge: number;
  readonly diabetes: number;

  constructor({
    age,
    ageSquared,
    totalCholesterol,
    totalCholesterolAge,
    hdlCholesterol,
    hdlCholesterolAge,
    sbpTreated,
    sbpUntreated,
    smoking,
    smokingAge,
    diabetes,
  }: {
    age: number;
    ageSquared: number;
    totalCholesterol: number;
    totalCholesterolAge: number;
    hdlCholesterol: number;
    hdlCholesterolAge: number;
    sbpTreated: number;
    sbpUntreated: number;
    smoking: number;
    smokingAge: number;
    diabetes: number;
  }) {
    this.age = age;
    this.ageSquared = ageSquared;
    this.totalCholesterol = totalCholesterol;
    this.totalCholesterolAge = totalCholesterolAge;
    this.hdlCholesterol = hdlCholesterol;
    this.hdlCholesterolAge = hdlCholesterolAge;
    this.sbpTreated = sbpTreated;
    this.sbpUntreated = sbpUntreated;
    this.smoking = smoking;
    this.smokingAge = smokingAge;
    this.diabetes = diabetes;
  }
}
import React from "react";
import { Center, Text } from "@chakra-ui/react";

const Home = () => {
  return (
    <Center h={"full"} minH={"full"} w={"full"} bg={"white"} rounded={"md"}>
      <Text as={"h1"} fontSize={"xxx-large"}>
        Welcome to Oben
      </Text>
    </Center>
  );
};

export default Home;

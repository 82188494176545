import { ASCVDRiskCoefficients } from '../constants/ascvd-risk-coefficients';
import { Sex, Race } from '../constants/core-enums';
import { ASCVDRisk } from '../models/ascvd-risk';
import { ClientUser } from '../models/client-user';
import { ClientUserService } from '../services/client-user-service';

export class ASCVDRiskUtils {
  static async createASCVDRiskObject(clientId: string): Promise<ASCVDRisk> {
    // Fetch the necessary data
    const clientDb = new ClientUserService();
    const client: ClientUser = await clientDb.getClientUser(clientId);

    const now = new Date();

    // create partial object
    const risk = new ASCVDRisk({
      id: "", // temporary value
      clientId: clientId,
      age: this._calculateAge(new Date(client.dob || now)),
      sex: Sex.Male, // TODO: fix - retrieve from client.sex
      race: Race.Black, // TODO: fix - retrieve from client.race
      totalCholesterol: client.latestCholesterolReading?.total || 0,
      HDLCholesterol: client.latestCholesterolReading?.HDL || 0,
      systolicBp: client.currentBpReading?.systolic || 0,
      isTreatedForHypertension: client.isTreatedForHypertension,
      isDiabetic: client.isDiabetic,
      isSmoker: client.isSmoker,
      riskDate: now,
      score: 0, // temporary value
      isInvalid: false,
      invalidDate: null,
      invalidatorType: null,
      invalidatorId: null
    });

    const ascvdRiskScore = this._calculateASCVDRiskScore(risk);
    risk.score = ascvdRiskScore;

    return risk;
  }

  static _calculateAge(birthDate: Date): number {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // Calculate the ASCVD risk score based on the risk factors and coefficients for the user.
  // The formula is based on the Pooled Cohort Equations from the American College of Cardiology.
  static _calculateASCVDRiskScore(riskFactors: ASCVDRisk): number | null {
    if ((riskFactors.age < 40) || 
      (riskFactors.age > 79) || 
      (riskFactors.totalCholesterol === 0) || 
      (riskFactors.HDLCholesterol === 0) || 
      (riskFactors.systolicBp === 0)) {
      return null;
    }

    const key = `${riskFactors.sex}_${riskFactors.race}`;
    const coeffs = ASCVDRiskCoefficients.coefficients[key];

    const lnAge = Math.log(riskFactors.age);
    const lnAgeSquared = Math.pow(lnAge, 2) as number;
    const lnTotalChol = Math.log(riskFactors.totalCholesterol);
    const lnHdl = Math.log(riskFactors.HDLCholesterol);
    const lnSysTreated = riskFactors.isTreatedForHypertension ? Math.log(riskFactors.systolicBp) : 0.0;
    const lnSysUntreated = !riskFactors.isTreatedForHypertension ? Math.log(riskFactors.systolicBp) : 0.0;
    const ageTotalChol = lnAge * lnTotalChol;
    const ageHdl = lnAge * lnHdl;
    const ageSysTreated = lnAge * lnSysTreated;
    const ageSysUntreated = lnAge * lnSysUntreated;
    const smoker = riskFactors.isSmoker ? 1.0 : 0.0;
    const ageSmoker = riskFactors.isSmoker ? lnAge : 0.0;
    const diabetes = riskFactors.isDiabetic ? 1.0 : 0.0;

    const sumOfFactors =
      coeffs.age * lnAge +
      coeffs.ageSquared * lnAgeSquared +
      coeffs.totalCholesterol * lnTotalChol +
      coeffs.totalCholesterolAge * ageTotalChol +
      coeffs.hdlCholesterol * lnHdl +
      coeffs.hdlCholesterolAge * ageHdl +
      coeffs.sbpTreated * ageSysTreated +
      coeffs.sbpUntreated * ageSysUntreated +
      coeffs.smoking * smoker +
      coeffs.smokingAge * ageSmoker +
      coeffs.diabetes * diabetes;

      const baselineSurvival = ASCVDRiskCoefficients.baselineSurvival[key];
      const meanCoefficient = ASCVDRiskCoefficients.meanCoefficient[key];
      
    const risk = 1.0 - Math.pow(baselineSurvival, Math.exp(sumOfFactors - meanCoefficient));

    return Math.round(risk * 100); // convert to percentage
  }
}
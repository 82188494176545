import { DocumentSnapshot } from "firebase/firestore";

import { ASCVDRisk } from "./ascvd-risk";
// import { CoreEnums, ProfileTag, TagChangeAction } from '../constants/core-enums';
// import { TagEnums } from '../constants/tag-enums';
// import { BpReadingService } from '../services/bp-reading-service';
import { CholesterolReading } from "./cholesterol-reading";
import { ClientUserService } from "../services/client-user-service";
// import { CoreUtils } from '../utils/core-utils';
// import { JournalService } from '../services/journal-service';
// import { JourneyService } from '../services/journey-service';
import { Address } from "./address";
import { AppInfo } from "./app-info";
import { SystemInfo } from "./system-info";
import { BpReading } from "./bp-reading";
// import { ClientUserBpReminder } from './client-user-bp-reminder';
// import { ClientUserCurrentJourney } from './client-user-current-journey';
// import { ClientUserWellnessProfiles } from './client-user-wellness-profiles';
// import { JourneyContent } from './journey-content';
// import { TagJournalEntry } from './tag-journal-entry';
import { UserName } from "./user-name";
// import { WellnessCategoryDetail } from './wellness-category-detail';
// import { WELLNESS-CATEGORIES-LIST } from '../selection-lists/wellness-categories-list';

export interface IClientUserData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  systemInfo: SystemInfo;
  // client-specific fields
  // avatar: ClientAvatar; // TODO: Uncomment this line after creating the ClientAvatar class
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string | null;
  sex: string | null;
  gender: string | null;
  dob: string | null;
  ethnicities: string[];
  address: Address | null;
  registrationDate: Date | null;
  onboardingDate: Date | null; // date that the user completed their onboarding
  enrollmentDate: Date | null; // date that the user completed their onboarding
  currentProgramStartDate: Date | null; // midnight-based; date of first "activity" in the current 2-week cycle
  lastActivityDate: Date | null;
  // currentJourney: ClientUserCurrentJourney;
  journeyIsComplete: boolean;
  // currentWellnessProfiles: ClientUserWellnessProfiles;
  currentBpReading: BpReading | null; // latest BP reading
  currentCarePlanId: string | null;
  // profileTags: ProfileTag[];
  // bpReminder: ClientUserBpReminder;
  // shownBpSummaryDates: string[]; // logged/shown bp duration dates(2 week data)
  firstTimeLoginPopup: boolean;
  hasShownWelcomeVideoOnce: boolean;
  viewedContent: string[];
  // focusArea: WellnessCategory | null;
  // outreachStatus: OutreachStatus;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  latestCholesterolReading: CholesterolReading | null;
  latestASCVDRisk: ASCVDRisk | null;
}

export class ClientUser {
  // common fields
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  systemInfo: SystemInfo;
  // client-specific fields
  // avatar: ClientAvatar; // TODO: Uncomment this line after creating the ClientAvatar class
  payerId: string | null;
  primaryCareProvId: string | null;
  placeBasedCareProvId: string | null;
  sex: string | null;
  gender: string | null;
  dob: string | null;
  ethnicities: string[];
  address: Address | null;
  registrationDate: Date | null;
  onboardingDate: Date | null; // date that the user completed their onboarding
  enrollmentDate: Date | null; // date that the user completed their onboarding
  currentProgramStartDate: Date | null; // midnight-based; date of first "activity" in the current 2-week cycle
  lastActivityDate: Date | null;
  // currentJourney: ClientUserCurrentJourney;
  journeyIsComplete: boolean;
  // currentWellnessProfiles: ClientUserWellnessProfiles;
  currentBpReading: BpReading | null; // latest BP reading
  currentCarePlanId: string | null;
  // profileTags: ProfileTag[];
  // bpReminder: ClientUserBpReminder;
  // shownBpSummaryDates: string[]; // logged/shown bp duration dates(2 week data)
  firstTimeLoginPopup: boolean;
  hasShownWelcomeVideoOnce: boolean;
  viewedContent: string[];
  // focusArea: WellnessCategory | null;
  // outreachStatus: OutreachStatus;
  isTreatedForHypertension: boolean;
  isDiabetic: boolean;
  isSmoker: boolean;
  latestCholesterolReading: CholesterolReading | null;
  latestASCVDRisk: ASCVDRisk | null;

  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    appInfo,
    systemInfo,
    // avatar, // TODO: Uncomment this line after creating the ClientAvatar class
    payerId,
    primaryCareProvId,
    placeBasedCareProvId,
    sex,
    gender,
    dob,
    ethnicities,
    address,
    registrationDate,
    onboardingDate,
    enrollmentDate,
    currentProgramStartDate,
    lastActivityDate,
    // currentJourney,
    journeyIsComplete,
    // currentWellnessProfiles,
    currentBpReading,
    currentCarePlanId,
    // profileTags,
    // bpReminder,
    // shownBpSummaryDates,
    firstTimeLoginPopup,
    hasShownWelcomeVideoOnce,
    viewedContent,
    // focusArea,
    // outreachStatus,
    isTreatedForHypertension,
    isDiabetic,
    isSmoker,
    latestCholesterolReading,
    latestASCVDRisk
  }: IClientUserData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    this.appInfo = appInfo;
    this.systemInfo = systemInfo;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the ClientAvatar class
    this.payerId = payerId;
    this.primaryCareProvId = primaryCareProvId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.sex = sex;
    this.gender = gender;
    this.dob = dob;
    this.ethnicities = ethnicities;
    this.address = address;
    this.registrationDate = registrationDate;
    this.onboardingDate = onboardingDate;
    this.enrollmentDate = enrollmentDate;
    this.currentProgramStartDate = currentProgramStartDate;
    this.lastActivityDate = lastActivityDate;
    // this.currentJourney = currentJourney;
    this.journeyIsComplete = journeyIsComplete;
    // this.currentWellnessProfiles = currentWellnessProfiles;
    this.currentBpReading = currentBpReading;
    this.currentCarePlanId = currentCarePlanId;
    // this.profileTags = profileTags;
    // this.bpReminder = bpReminder;
    // this.shownBpSummaryDates = shownBpSummaryDates;
    this.firstTimeLoginPopup = firstTimeLoginPopup;
    this.hasShownWelcomeVideoOnce = hasShownWelcomeVideoOnce;
    this.viewedContent = viewedContent;
    // this.focusArea = focusArea;
    // this.outreachStatus = outreachStatus;
    this.isTreatedForHypertension = isTreatedForHypertension;
    this.isDiabetic = isDiabetic;
    this.isSmoker = isSmoker;
    this.latestCholesterolReading = latestCholesterolReading;
    this.latestASCVDRisk = latestASCVDRisk;
  }

  //
  // Getters
  //

  // TODO: Remove? Currently not used (also see 'numberOfDaysEnrolled' below for similar calculation)
  get dayInProgram(): number {
    if (!this.currentProgramStartDate) {
      return 0;
    } else {
      const startDate = new Date(this.currentProgramStartDate); // should be midnight-based
      if (isNaN(startDate.getTime())) {
        return 0;
      } else {
        return (
          Math.floor(
            (Date.now() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          ) + 1
        ); // add one because .floor returns whole days
      }
    }
  }

  get numberOfDaysEnrolled(): number {
    if (!this.enrollmentDate) {
      return 0;
    } else {
      const startDate = new Date(this.enrollmentDate); // should be midnight-based
      if (isNaN(startDate.getTime())) {
        return 0;
      } else {
        return (
          Math.floor(
            (Date.now() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          ) + 1
        ); // add one because .floor returns whole days
      }
    }
  }

  get isOnboardingComplete(): boolean {
    return !(
      (
        !this.name.display ||
        !this.name.first ||
        !this.name.last ||
        !this.gender || // simple field
        !this.dob || // simple field
        !this.phoneNumber || // simple field
        this.ethnicities.length === 0
      ) // complex field (list)
      // TODO: re-enable these checks when the fields are added back or remove them if they are obsolete
      // ||
      // !(this.currentWellnessProfiles?.medical.onboardingIsComplete ?? false) ||
    );
  }

  // get selectedGoals(): string[] {
  //   const goals: string[] = [];
  //   WELLNESS_CATEGORIES_LIST.forEach(detail => {
  //     switch (detail.category) {
  //       case WellnessCategory.Exercise:
  //         if (this.currentWellnessProfiles?.exercise.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //       case WellnessCategory.Nutrition:
  //         if (this.currentWellnessProfiles?.nutrition.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //       case WellnessCategory.Stress:
  //         if (this.currentWellnessProfiles?.stress.chosenByUser ?? false) {
  //           goals.push(detail.goal);
  //         }
  //         break;
  //     }
  //   });
  //   return goals;
  // }

  get programDurationIsComplete(): boolean {
    // TODO: select which date to use as "start date"
    return false;
  }

  // Determine if bp has been entered today.
  get hasEnteredBpForToday(): boolean {
    return this.currentBpReading?.wasToday ?? false;
  }

  // // Method is not currently used
  // hasCondition(value: string): boolean {
  //   return this.currentWellnessProfiles?.medical.a1medicalConditions?.includes(value) ?? false;
  // }

  // Delete the user (and all their data) from the Firestore database
  async delete(): Promise<void> {
    const clientDb = new ClientUserService();
    await clientDb.deleteClientUser(this.uid);
  }

  // // Methods for manipulating (setting/clearing) profile tags
  // clearTag(tag: ProfileTag): void {
  //   if (this.profileTags.includes(tag)) {
  //     this.profileTags = this.profileTags.filter(item => item !== tag);
  //     this._logTagChange(tag, TagChangeAction.Clear);
  //   }
  // }

  // setTag(tag: ProfileTag): void {
  //   if (!this.profileTags.includes(tag)) {
  //     this.profileTags.push(tag);
  //     this._logTagChange(tag, TagChangeAction.Set);
  //   }
  // }

  // _logTagChange(tag: ProfileTag, action: TagChangeAction): void {
  //   const _journalDb = new JournalService();
  //   const journalEntry = new TagJournalEntry({
  //     timestamp: new Date().toISOString(),
  //     tag: CoreUtils.enumToString(tag),
  //     action: CoreUtils.enumToString(action),
  //   });
  //   _journalDb.addJournalEntry(this.uid, journalEntry); // will complete asynchronously
  // }

  async updateDb(): Promise<void> {
    const db = new ClientUserService();
    await db.updateClientUser(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): ClientUser {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return ClientUser.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): ClientUser {
    const userName: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string | null = data["phoneNumber"] ?? null;
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset = data["pwdNeedsReset"] ?? false;
    const appInfo: AppInfo = AppInfo.fromMap(data["appInfo"] ?? {});
    const systemInfo: SystemInfo = SystemInfo.fromMap(data["systemInfo"] ?? {});
    // const avatar: ClientAvatar = ClientAvatarHelper.valueOf(data["avatar"] ?? "") ?? ClientAvatar.zero;
    const payerId: string | null = data["payerId"] ?? null;
    const primaryCareProvId: string | null = data["primaryCareProvId"] ?? null;
    const placeBasedCareProvId: string | null =
      data["placeBasedCareProvId"] ?? null;
    const sex: string | null = data["sex"] ?? null;
    const gender: string | null = data["gender"] ?? null;
    const dob: string | null = data["dob"];
    const ethnicities: string[] = data["ethnicities"] ?? [];
    const address: Address | null = data["address"]
      ? Address.fromMap(data["address"])
      : null;
    const registrationDate: Date | null = data["registrationDate"]
      ? new Date(data["registrationDate"])
      : null;
    const onboardingDate: Date | null = data["onboardingDate"]
      ? new Date(data["onboardingDate"])
      : null;
    const enrollmentDate: Date | null = data["enrollmentDate"]
      ? new Date(data["enrollmentDate"])
      : null;
    const currentProgramStartDate: Date | null =
      new Date(data["currentProgramStartDate"]) ?? null;
    const lastActivityDate: Date | null = data["lastActivityDate"]
      ? new Date(data["lastActivityDate"])
      : null;
    // const currentJourney: ClientUserCurrentJourney = ClientUserCurrentJourney.fromMap(data["currentJourney"] ?? {});
    const journeyIsComplete: boolean = data["journeyIsComplete"] ?? false;
    // const currentWellnessProfiles: ClientUserWellnessProfiles = ClientUserWellnessProfiles.fromMap(data["currentWellnessProfiles"] ?? {});
    const currentBpReading: BpReading | null = data["currentBpReading"]
      ? BpReading.fromMap("unusedField", data["currentBpReading"])
      : null;
    const currentCarePlanId: string | null = data["currentCarePlanId"] ?? null;
    // const profileTagsStrings: string[] = data["profileTags"] ?? [];
    // const profileTags: ProfileTag[] = [];
    // profileTagsStrings.forEach((element: string) => {
    //   const tag = ProfileTagHelper.valueOf(element);
    //   if (tag) {
    //     profileTags.push(tag);
    //   }
    // });
    // const bpReminder: ClientUserBpReminder | null = data["bpReminder"] ? ClientUserBpReminder.fromMap(data["bpReminder"]) : null;
    // const shownBpSummaryDates: string[] = data["shownBpSummaryDates"] ?? [];
    const firstTimeLoginPopup: boolean = data["firstTimeLoginPopup"] ?? false;
    const hasShownWelcomeVideoOnce: boolean =
      data["hasShownWelcomeVideoOnce"] ?? false;
    const viewedContent: string[] = data["viewedContent"] ?? [];
    // const focusArea: WellnessCategory = WellnessCategoryHelper.valueOf(data["focusArea"] ?? "") ?? null;
    // const outreachStatus: OutreachStatus = OutreachStatusHelper.valueOf(data["outreachStatus"] ?? "") ?? OutreachStatus.New;
    const isTreatedForHypertension: boolean =
      data["isTreatedForHypertension"] ?? false;
    const isDiabetic: boolean = data["isDiabetic"] ?? false;
    const isSmoker: boolean = data["isSmoker"] ?? false;
    const latestCholesterolReading: CholesterolReading | null = data[
      "latestCholesterolReading"
    ]
      ? CholesterolReading.fromMap(
          "unusedField",
          data["latestCholesterolReading"]
        )
      : null;
    const latestASCVDRisk: ASCVDRisk | null = data["latestASCVDRisk"]
      ? ASCVDRisk.fromMap("unusedField", data["latestASCVDRisk"])
      : null;

    return new ClientUser({
      uid: uid,
      name: userName,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      appInfo: appInfo,
      systemInfo: systemInfo,
      // avatar: avatar,
      payerId: payerId,
      primaryCareProvId: primaryCareProvId,
      placeBasedCareProvId: placeBasedCareProvId,
      sex: sex,
      gender: gender,
      dob: dob,
      ethnicities: ethnicities,
      address: address,
      registrationDate: registrationDate,
      onboardingDate: onboardingDate,
      enrollmentDate: enrollmentDate,
      currentProgramStartDate: currentProgramStartDate,
      lastActivityDate: lastActivityDate,
      // currentJourney: currentJourney,
      journeyIsComplete: journeyIsComplete,
      // currentWellnessProfiles: currentWellnessProfiles,
      currentBpReading: currentBpReading,
      currentCarePlanId: currentCarePlanId,
      // profileTags: profileTags,
      // bpReminder: bpReminder,
      // shownBpSummaryDates: shownBpSummaryDates,
      firstTimeLoginPopup: firstTimeLoginPopup,
      hasShownWelcomeVideoOnce: hasShownWelcomeVideoOnce,
      viewedContent: viewedContent,
      // focusArea: focusArea,
      // outreachStatus: outreachStatus,
      isTreatedForHypertension: isTreatedForHypertension,
      isDiabetic: isDiabetic,
      isSmoker: isSmoker,
      latestCholesterolReading: latestCholesterolReading,
      latestASCVDRisk: latestASCVDRisk
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "uid": this.uid, // not necessary to save UID (it is already part of the Firestore document)
      name: this.name.toJson(),
      email: this.email,
      phoneNumber: this.phoneNumber,
      enabled: this.enabled,
      pwdNeedsReset: this.pwdNeedsReset,
      appInfo: this.appInfo.toJson(),
      systemInfo: this.systemInfo.toJson(),
      // "avatar": this.avatar.name,
      payerId: this.payerId,
      primaryCareProvId: this.primaryCareProvId,
      placeBasedCareProvId: this.placeBasedCareProvId,
      sex: this.sex,
      gender: this.gender,
      dob: this.dob,
      ethnicities: [...this.ethnicities],
      address: this.address?.toJson() ?? null,
      registrationDate: this.registrationDate?.toISOString() ?? null,
      onboardingDate: this.onboardingDate?.toISOString() ?? null,
      enrollmentDate: this.enrollmentDate?.toISOString() ?? null,
      currentProgramStartDate:
        this.currentProgramStartDate?.toISOString() ?? null,
      lastActivityDate: this.lastActivityDate?.toISOString() ?? null,
      // "currentJourney": this.currentJourney.toJson(),
      journeyIsComplete: this.journeyIsComplete,
      // "currentWellnessProfiles": this.currentWellnessProfiles.toJson(),
      currentBpReading: this.currentBpReading?.toJson() ?? null,
      currentCarePlanId: this.currentCarePlanId,
      // "profileTags": this.profileTags,
      // "bpReminder": this.bpReminder?.toJson(),
      // "shownBpSummaryDates": [...this.shownBpSummaryDates],
      firstTimeLoginPopup: this.firstTimeLoginPopup,
      hasShownWelcomeVideoOnce: this.hasShownWelcomeVideoOnce,
      viewedContent: [...this.viewedContent],
      // "focusArea": this.focusArea ? CoreUtils.enumToString(this.focusArea) : null,
      // "outreachStatus": this.outreachStatus,
      isTreatedForHypertension: this.isTreatedForHypertension,
      isDiabetic: this.isDiabetic,
      isSmoker: this.isSmoker,
      latestCholesterolReading: this.latestCholesterolReading?.toJson() ?? null,
      latestASCVDRisk: this.latestASCVDRisk?.toJson() ?? null
    };
  }
}

import React, { createContext, ReactNode, useReducer } from "react";
import { WebUser } from "@oben-core-web/models/web-user";
import { authReducer, IAuthState, initialState } from "@/reducers/authReducer";

interface AuthContextType {
  state: IAuthState;
  onLogin: (user: WebUser) => void;
  beforeLogout: () => void;
  setDetachAuthListener: (detach: () => void) => void;
}

// Create AuthContext
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

// AuthProvider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const onLogin = (user: WebUser) => {
    dispatch({ type: "ON_LOGIN", payload: { currentUser: user } });
  };

  const beforeLogout = () => {
    dispatch({ type: "BEFORE_LOGOUT" });
  };

  const setDetachAuthListener = (detach: () => void) => {
    dispatch({
      type: "SET_DETACH_LISTENER",
      payload: { detachAuthListener: detach }
    });
  };

  return (
    <AuthContext.Provider
      value={{ state, onLogin, beforeLogout, setDetachAuthListener }}
    >
      {children}
    </AuthContext.Provider>
  );
};

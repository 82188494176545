import { DocumentSnapshot } from "firebase/firestore";

import { UserType, UserTypeHelper } from "../constants/core-enums";
import { ClientNoteService } from "../services/client-note-service";

export enum ClientNoteType {
  Unknown = "Unknown",
  Appointment = "Appointment",
  CarePlan = "CarePlan",
  ClientTask = "ClientTask",
  MedAdherence = "MedAdherence",
  Medication = "Medication",
  General = "General"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class ClientNoteTypeHelper {
  static valueOf(searchString: string | null): ClientNoteType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(ClientNoteType).includes(
      searchString as ClientNoteType
    ) //
      ? (searchString as ClientNoteType)
      : null;
  }
}

/// ClientNote records are maintained in a subcollection on ClientUser.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IClientNoteData {
  modelId: string;
  clientId: string;
  authorId: string;
  authorType: UserType;
  createdDate: Date;
  noteSourceId: string;
  noteType: ClientNoteType;
  summary: string;
  details: string;
}

export class ClientNote {
  modelId: string;
  clientId: string;
  authorId: string;
  authorType: UserType;
  createdDate: Date;
  noteSourceId: string;
  noteType: ClientNoteType;
  summary: string;
  details: string;

  constructor({
    modelId,
    clientId,
    authorId,
    authorType,
    createdDate,
    noteSourceId,
    noteType,
    summary,
    details
  }: IClientNoteData) {
    this.modelId = modelId;
    this.clientId = clientId;
    this.authorId = authorId;
    this.authorType = authorType;
    this.createdDate = createdDate;
    this.noteSourceId = noteSourceId;
    this.noteType = noteType;
    this.summary = summary;
    this.details = details;
  }

  // Misc helper methods
  async updateDb(): Promise<void> {
    const db = new ClientNoteService();
    await db.updateClientNote(this);
  }

  // Static methods for creating instances
  static fromFirestore(docSnap: DocumentSnapshot): ClientNote {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return ClientNote.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): ClientNote {
    return new ClientNote({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      clientId: data["clientId"] ?? "",
      authorId: data["authorId"] ?? "",
      authorType:
        UserTypeHelper.valueOf(data["authorType"]) ?? UserType.Unknown,
      createdDate: data["createdDate"]
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1/1/0001
      noteSourceId: data["noteSourceId"] ?? "",
      noteType:
        ClientNoteTypeHelper.valueOf(data["noteType"] ?? "") ??
        ClientNoteType.Unknown,
      summary: data["summary"] ?? "",
      details: data["details"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId, // with subcollections we must save ID within the Firestore document itself
      clientId: this.clientId,
      authorId: this.authorId,
      authorType: this.authorType,
      createdDate: this.createdDate?.toISOString() ?? null,
      noteSourceId: this.noteSourceId,
      noteType: this.noteType,
      summary: this.summary,
      details: this.details
    };
  }
}

import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import RootRouter from "./routers/index";
import { AuthProvider } from "./context/AuthContext";
// import { extendTheme } from '@chakra-ui/react';

// // Extend the Chakra UI theme
// const theme = extendTheme({
//   fonts: {
//     heading: `'Red Hat Display', sans-serif`, // Set for headings
//     body: `'Red Hat Display', sans-serif`, // Set for body text
//   },
// });
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider
    // theme={theme}
    >
      <AuthProvider>
        <RootRouter />
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Box,
  VStack,
  Flex,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  IconButton,
  Tooltip,
  Spinner
} from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";
import IndexSearch from "./IndexSearch";
import useWebUsers from "@/hooks/useWebUsers";
import useBarbershops from "@/hooks/useBarbershops";
import appointmentSchema from "@/resolvers/appointment";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUserNameData, UserName } from "@oben-core-web/models/user-name";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours";
import { convertToLocalTZ } from "@/lib/timezone";
import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { Link, useNavigate } from "react-router-dom";
// import { BillableEventService } from "@oben-core-web/services/billable-event-service";
// import {
//   BillableEvent,
//   BillableEventType
// } from "@oben-core-web/models/billable-event";
// import { BillableItemService } from "@oben-core-web/services/billable-item-service";
// import { BillableItem } from "@oben-core-web/models/billable-item";
// import {
//   BillStatus,
//   BillStatusChange
// } from "@oben-core-web/models/bill-status-change";
// import { httpsCallable } from "firebase/functions";
// import { CloudFunctions } from "../../../firebase";
import useCurrentUser from "@/hooks/useCurrentUser";
import { AppointmentTransactionService } from "@oben-core-web/services/appointment-transaction-service";
import {
  AppointmentStatus,
  AppointmentStatusChange
} from "@oben-core-web/models/appointment-status-change";
import { EditIcon } from "@chakra-ui/icons";
// import { MdOutlinePersonOff } from "react-icons/md";
import { FaCalendarTimes } from "react-icons/fa";
import { FaFileMedical } from "react-icons/fa6";
import { FaUserAltSlash } from "react-icons/fa";
import useBarber from "@/hooks/useBarber";
import HoverHelp from "../atoms/HoverHelp";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { Barbershop } from "@oben-core-web/models/barbershop";

interface IAppointmentForm {
  id?: string;
  startDateTime: Date;
  length: number;
  pharmacistId: string;
  // pharmacistName: IUserNameData;
  billableEventId: string;
  statusChanges: AppointmentStatusChange[];
  barbershopId: string;
  barbershopName: string;
  pharmacistName: UserName;
  barberId: string;
  clientId: string;
  clientName: UserName;
  barberName: UserName;
}

interface ISchedulerAppointmentFormProps {
  dialogOpen: boolean;
  appointment: IAppointmentForm;
  disableFormActions: boolean;
  toggleDialog: () => void;
}

const SchedulerAppointmentForm = React.memo(
  ({
    dialogOpen,
    appointment,
    disableFormActions,
    toggleDialog
  }: ISchedulerAppointmentFormProps) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const { currentUser } = useCurrentUser();
    const { webUsers } = useWebUsers();
    // TODO: make sure that barbers have workplaces in the model and use that here.
    const [barbershopOptions, setBarbershopOptions] = useState<Barbershop[]>(
      []
    );
    const { barbershops } = useBarbershops();
    const { barber, fetchBarber } = useBarber({
      uid: appointment?.barberId,
      initialFetch: false
    });
    const actionDisclosure = useDisclosure();
    const [actionType, setActionType] = useState<
      "Completed" | "No-show" | "Cancelled"
    >();
    const [editMode, setIsEditMode] = useState(false);
    const { fetchCustomWorkingHours } = useCustomWorkingHours();
    const isExistingAppointment = !!appointment?.id;
    const { handleSubmit, setValue, reset, control, watch } = useForm({
      defaultValues: {
        id: appointment?.id || "",
        startDateTime: appointment?.startDateTime || new Date(),
        length: appointment?.length || 30,
        billableEventId: appointment?.billableEventId || "",
        pharmacistId: appointment?.pharmacistId || "",
        pharmacistName: appointment?.pharmacistName || {
          first: "",
          last: "",
          display: ""
        },
        barbershopId: appointment?.barbershopId || "",
        barberId: appointment?.barberId || "",
        clientId: appointment?.clientId || "",
        clientName:
          appointment?.clientName ??
          ({
            first: "",
            last: "",
            display: ""
          } as IUserNameData),
        barberName:
          appointment?.barberName ??
          ({
            first: "",
            last: "",
            display: ""
          } as IUserNameData)
      } as any,
      resolver: yupResolver(appointmentSchema)
    });

    const [localStartDateTime, setLocalStartDateTime] = useState(
      appointment?.startDateTime
        ? DateTime.fromJSDate(appointment.startDateTime).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm")
    );

    useEffect(() => {
      if (appointment) {
        if (appointment.id) {
          setIsEditMode(false);
          fetchBarber(appointment.barberId);
        } else {
          setIsEditMode(true);
        }
        setLocalStartDateTime(
          DateTime.fromJSDate(appointment.startDateTime).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        );
        reset(appointment);
      }
    }, [appointment, reset, fetchBarber]);

    const handleFormSubmit = async (data: IAppointmentForm) => {
      try {
        setLoading(true);
        const appointmentInterval = Interval.fromDateTimes(
          DateTime.fromJSDate(new Date(localStartDateTime)),
          DateTime.fromJSDate(new Date(localStartDateTime)).plus({
            minutes: data.length
          })
        );
        if (new Date(data.startDateTime) < new Date()) {
          toast({
            status: "error",
            description: "Cannot create an appointment in the past"
          });
          setLoading(false);
          return;
        }
        // validation helpers
        const setDateTime = (date: Date, hour: number, minute: number) => {
          return DateTime.fromJSDate(new Date(date))
            .set({ hour, minute })
            .toJSDate();
        };

        const validateWorkingHours = (
          workingHours: { start: Date; end: Date }[]
        ) => {
          return workingHours.some((wh) => {
            const workingHourInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(convertToLocalTZ(wh.start as Date)),
              DateTime.fromJSDate(convertToLocalTZ(wh.end as Date))
            );
            return (
              workingHourInterval.start! <= appointmentInterval.start! &&
              workingHourInterval.end! >= appointmentInterval.end!
            );
          });
        };
        // fetch relevant working hours
        const dayCustomHours = await fetchCustomWorkingHours({
          userId: data.pharmacistId,
          start: setDateTime(new Date(localStartDateTime), 0, 0),
          end: setDateTime(new Date(localStartDateTime), 23, 59)
        });
        const pharmacist = webUsers.find((wu) => wu.uid === data.pharmacistId)!;
        const pharmacistWorkingHours =
          pharmacist.defaultWorkingHours
            .filter(
              (wh) =>
                wh.dayOfWeek ===
                DateTime.fromJSDate(new Date(localStartDateTime)).weekday
            )
            .map((dwh) => ({
              start: setDateTime(
                new Date(localStartDateTime),
                dwh.startTime.getHours(),
                dwh.startTime.getMinutes()
              ),
              end: setDateTime(
                new Date(localStartDateTime),
                dwh.endTime.getHours(),
                dwh.endTime.getMinutes()
              )
            })) ?? [];

        const hasValidCustomHours =
          !!dayCustomHours.length &&
          validateWorkingHours(
            dayCustomHours.map((cwh) => ({ start: cwh.start!, end: cwh.end! }))
          );
        const hasValidDefaultHours =
          !dayCustomHours.length &&
          !!pharmacistWorkingHours.length &&
          validateWorkingHours(pharmacistWorkingHours);

        if (!dayCustomHours.length && !pharmacistWorkingHours.length) {
          toast({
            description: "Pharmacist is not working that day",
            status: "error"
          });
        }
        if (
          hasValidCustomHours ||
          (dayCustomHours.length === 0 && hasValidDefaultHours)
        ) {
          const appointmentService = new AppointmentService();
          const appointment = new Appointment({
            ...data,
            id: isExistingAppointment ? data.id! : "",
            clientName: new UserName(data.clientName),
            pharmacistName: pharmacist.name,
            date: new Date(localStartDateTime),
            statusChanges: data.statusChanges ?? [],
            billableEventId: "",
            barberName: new UserName(data.barberName),
            barbershopName: barbershops.find((b) => b.id === data.barbershopId)!
              .businessName
          });
          if (isExistingAppointment) {
            await appointmentService.updateAppointment(appointment).then(() => {
              toast({ description: "Appointment updated!", status: "success" });
              toggleDialog();
            });
          } else {
            appointment.statusChanges = [
              new AppointmentStatusChange({
                status: AppointmentStatus.New,
                date: new Date(),
                editorId: pharmacist.uid,
                editorType: pharmacist.userType,
                details: "Appointment created"
              })
            ];
            await appointmentService.addAppointment(appointment).then(() => {
              toast({ description: "Appointment created!", status: "success" });
              setLoading(false);
              toggleDialog();
            });
          }
        } else {
          toast({
            description: "Pharmacist is not working at that time",
            status: "error"
          });
        }
      } catch (e) {
        toast({
          description: "Something went wrong. Please try again.",
          status: "error"
        });
      } finally {
        setLoading(false);
      }
    };

    const handlePatientSearchResultClick = ({ id, name }: any) => {
      setValue("clientId", id);
      setValue("clientName", name);
    };

    const handleBarberSearchResultClick = async ({
      id,
      name,
      barbershopIds
    }: any) => {
      setValue("barberId", id);
      setValue("barberName", name);
      const barbershopService = new BarbershopService();
      const barbershops = await barbershopService.getBarbershops(barbershopIds);
      setBarbershopOptions(barbershops);
    };

    const customTransformItems = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        label: item.name.first + " " + item.name.last,
        name: item.name,
        id: item.objectID.split("/")[1] ?? item.objectID,
        ...(item.dob ? { dob: new Date(item.dob) } : {}),
        email: item.email
      }));
    };

    const billingEventCreated = !!appointment?.billableEventId;
    const appointmentDateHasPast =
      DateTime.fromJSDate(appointment?.startDateTime).set({
        hour: 0,
        minute: 0
      }) < DateTime.now();
    const isCancellable = useMemo(
      () =>
        DateTime.fromJSDate(appointment?.startDateTime).minus({
          days: barber?.cancelWindow ?? 0
        }) >= DateTime.now() &&
        DateTime.now() < DateTime.fromJSDate(appointment?.startDateTime),
      [appointment, barber]
    );
    const isAfterApptStart = useMemo(
      () => DateTime.fromJSDate(appointment?.startDateTime) <= DateTime.now(),
      [appointment]
    );

    return (
      <>
        <AppointmentActionDialog
          action={actionType}
          appointment={appointment}
          isOpen={actionDisclosure.isOpen}
          onClose={() => {
            setActionType(undefined);
            actionDisclosure.onClose();
          }}
        />

        <Modal isOpen={dialogOpen} onClose={toggleDialog} size='xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                {isExistingAppointment ? (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Flex>
                      <Text>Appointment with &nbsp;</Text>
                      <Link
                        to={`/patient/${appointment.clientId}`}
                        style={{ textDecoration: "underline" }}
                      >
                        {appointment.clientName.first +
                          " " +
                          appointment.clientName.last}
                      </Link>
                    </Flex>
                    <Flex>
                      <Tooltip
                        label={
                          !billingEventCreated ||
                          (!appointmentDateHasPast && !billingEventCreated)
                            ? "Appointment cannot be edited"
                            : "Edit Appointment"
                        }
                        hasArrow
                      >
                        <IconButton
                          aria-label='edit-appointment'
                          onClick={() => {
                            setIsEditMode((m) => !m);
                          }}
                          isDisabled={billingEventCreated}
                          icon={<EditIcon />}
                          variant='ghost'
                        />
                      </Tooltip>
                      {/* <Tooltip
                        label={
                          !billingEventCreated ||
                          (!appointmentDateHasPast && !billingEventCreated)
                            ? "Appointment cannot be edited"
                            : "Delete Appointment"
                        }
                        hasArrow
                      >
                        <IconButton
                          aria-label='delete-appointment'
                          onClick={() => {
                            setActionType("Delete");
                            actionDisclosure.onToggle();
                          }}
                          isDisabled={billingEventCreated}
                          icon={<DeleteIcon />}
                          variant='ghost'
                        />
                      </Tooltip> */}
                    </Flex>
                  </Flex>
                ) : (
                  <Text> Add an Appointment</Text>
                )}
              </Flex>
            </ModalHeader>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <ModalBody>
                <VStack spacing={4}>
                  <FormControl isDisabled={disableFormActions}>
                    <FormLabel>Date</FormLabel>
                    {editMode ? (
                      <Input
                        type='datetime-local'
                        value={localStartDateTime}
                        onChange={(e) => setLocalStartDateTime(e.target.value)}
                      />
                    ) : (
                      <Text>
                        {DateTime.fromJSDate(
                          new Date(localStartDateTime)
                        ).toFormat("MM/dd/yy - hh:mma")}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl isDisabled={disableFormActions}>
                    <FormLabel display={"flex"} alignItems={"flex-start"}>
                      Duration&nbsp;
                      <HoverHelp
                        label={"Duration in minutes"}
                        h={2.5}
                        w={2.5}
                      ></HoverHelp>
                    </FormLabel>
                    {editMode ? (
                      <Controller
                        name='length'
                        control={control}
                        render={({ field }) => (
                          <Input type='number' {...field} />
                        )}
                      />
                    ) : (
                      <Text>{watch("length")}</Text>
                    )}
                  </FormControl>
                  <FormControl isDisabled={disableFormActions}>
                    <FormLabel>Pharmacist</FormLabel>
                    {editMode ? (
                      <Controller
                        name='pharmacistId'
                        control={control}
                        render={({ field }) => (
                          <Select {...field}>
                            {webUsers.map((wu) => (
                              <option key={`webUser-${wu.uid}`} value={wu.uid}>
                                {wu.name.first + " " + wu.name.last}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    ) : (
                      <Text>
                        {watch("pharmacistName.first") +
                          " " +
                          watch("pharmacistName.last")}
                      </Text>
                    )}
                  </FormControl>
                  {!appointment?.id && (
                    <Box w={"full"}>
                      <FormLabel>Patient</FormLabel>
                      <IndexSearch
                        searchIndex='clients'
                        w={"full"}
                        // inputLabel="Patient"
                        filters={
                          currentUser?.placeBasedCareProvId
                            ? `placeBasedCareProvId:${currentUser.placeBasedCareProvId}`
                            : ""
                        }
                        placeholderText='Search by patient name'
                        transformItems={customTransformItems}
                        onResultClick={handlePatientSearchResultClick}
                        onClearSelection={() => {
                          setValue("clientId", "");
                          setValue("clientName", {
                            first: "",
                            last: "",
                            display: ""
                          } as IUserNameData);
                        }}
                      />
                    </Box>
                  )}
                  <Box w={"full"}>
                    <FormLabel>Barber</FormLabel>
                    {editMode ? (
                      <IndexSearch
                        w={"full"}
                        searchIndex='barbers'
                        // inputLabel="Barber"
                        filters={`stripeStatus:TransfersEnabled AND placeBasedCareProvIds:${
                          currentUser?.placeBasedCareProvId ?? ""
                        }`}
                        prefilledText={
                          appointment?.barberName && appointment?.barberId
                            ? `${appointment.barberName.first} ${appointment.barberName.last}`
                            : ""
                        }
                        placeholderText='Search by barber name'
                        transformItems={customTransformItems}
                        onResultClick={handleBarberSearchResultClick}
                        onClearSelection={() => {
                          setBarbershopOptions([]);
                        }}
                      />
                    ) : (
                      <Text>
                        {watch("barberName.first") +
                          " " +
                          watch("barberName.last")}
                      </Text>
                    )}
                  </Box>
                  <FormControl>
                    <FormLabel>Barbershop</FormLabel>
                    {editMode ? (
                      <Controller
                        name='barbershopId'
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder='Select a barbershop'
                            isDisabled={barbershopOptions.length === 0}
                            {...field}
                          >
                            {barbershopOptions?.map((b) => (
                              <option key={`barbershop-${b.id}`} value={b.id}>
                                {b.businessName}
                              </option>
                            ))}
                            {/* {barbershops.map((b) => (
                              <option key={`barbershop-${b.id}`} value={b.id}>
                                {b.businessName}
                              </option>
                            ))} */}
                          </Select>
                        )}
                      />
                    ) : (
                      <Text>{watch("barbershopName")}</Text>
                    )}
                  </FormControl>
                </VStack>
              </ModalBody>
              <ModalFooter display='flex' justifyContent={"flex-end"}>
                <HStack>
                  {isExistingAppointment && !editMode ? (
                    <>
                      <Tooltip
                        label={
                          billingEventCreated
                            ? "Appointment was already started"
                            : "Start Appointment"
                        }
                        hasArrow
                      >
                        <IconButton
                          variant='ghost'
                          aria-label='start-appt'
                          icon={<FaFileMedical />}
                          isDisabled={!!billingEventCreated}
                          onClick={() => {
                            if (!billingEventCreated) {
                              setActionType("Completed");
                              actionDisclosure.onToggle();
                            }
                          }}
                        >
                          Start Appointment
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        label={
                          billingEventCreated
                            ? "Appointment was already started"
                            : !isAfterApptStart
                            ? "Cannot mark as no-show"
                            : "Patient No-show"
                        }
                        hasArrow
                      >
                        <IconButton
                          variant='ghost'
                          aria-label='no-show-appt'
                          icon={<FaUserAltSlash />}
                          isDisabled={
                            !!billingEventCreated || !isAfterApptStart
                          }
                          onClick={() => {
                            if (!billingEventCreated) {
                              setActionType("No-show");
                              actionDisclosure.onToggle();
                            }
                          }}
                        >
                          Patient no-show
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        label={
                          billingEventCreated
                            ? "Appointment was already started"
                            : !isCancellable
                            ? "Past cancel window"
                            : "Cancel Appointment"
                        }
                        hasArrow
                      >
                        <IconButton
                          variant='ghost'
                          aria-label='appt-cancelled'
                          icon={<FaCalendarTimes />}
                          isDisabled={!!billingEventCreated || !isCancellable}
                          onClick={() => {
                            if (!billingEventCreated) {
                              setActionType("Cancelled");
                              actionDisclosure.onToggle();
                            }
                          }}
                        >
                          Appointment Cancelled
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}
                </HStack>
                <HStack>
                  {editMode && (
                    <>
                      <Button variant='ghost' onClick={toggleDialog}>
                        Cancel
                      </Button>
                      <Button colorScheme='blue' type='submit'>
                        {loading ? <Spinner /> : "Save"}
                      </Button>
                    </>
                  )}
                </HStack>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default SchedulerAppointmentForm;

const AppointmentActionDialog = ({
  action,
  appointment,
  isOpen,
  onClose
}: {
  action: "Completed" | "No-show" | "Cancelled" | undefined;
  appointment: IAppointmentForm;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const cancelRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const confirmAction = async () => {
    if (!appointment || !appointment?.id) return;
    setLoading(true);
    const appointmentService = new AppointmentService();
    const updatedAppointment = new Appointment({
      ...appointment,
      date: appointment.startDateTime,
      id: appointment.id!
    });
    let statusDetails: string = "";
    switch (action) {
      case "Completed":
        statusDetails = "Appointment Started";
        break;
      case "Cancelled":
        statusDetails = "Appointment cancelled";
        break;
      case "No-show":
        statusDetails = "Client no-show";
        break;
      default:
        break;
    }
    const newStatusChanges = [...updatedAppointment.statusChanges];
    newStatusChanges.push(
      new AppointmentStatusChange({
        status:
          action === "Completed"
            ? AppointmentStatus.Completed
            : action === "Cancelled"
            ? AppointmentStatus.Canceled
            : action === "No-show"
            ? AppointmentStatus.NoShow
            : AppointmentStatus.Unknown,
        date: new Date(),
        editorId: currentUser!.uid,
        editorType: currentUser!.userType,
        details: statusDetails
      })
    );
    updatedAppointment.statusChanges = newStatusChanges;
    await appointmentService.updateAppointment(updatedAppointment);
    // create billableEvent and billableItem, then submit charge
    await AppointmentTransactionService.createBillableFromApptStatus({
      appointmentId: appointment!.id,
      placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
      statusChange:
        action === "Completed"
          ? AppointmentStatus.Completed
          : action === "Cancelled"
          ? AppointmentStatus.Canceled
          : action === "No-show"
          ? AppointmentStatus.NoShow
          : AppointmentStatus.Unknown,
      editorId: currentUser!.uid,
      approveImmediately: action === "Completed"
    })
      .then(() => {
        toast({
          status: "success",
          description:
            action === "Completed"
              ? "Payment submitted"
              : "Payment request submitted"
        });
      })
      .catch((err) => {
        toast({ status: "error", description: err.message });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
    if (action === "Completed") {
      // navigate to patientDetail and careplan
      navigate(`/patient/${appointment.clientId}`);
    }
  };

  // const handleDeleteAppointment = async () => {
  //   if (!appointment || !appointment?.id) return;
  //   const appointmentService = new AppointmentService();
  //   const updatedAppointment = new Appointment({
  //     ...appointment,
  //     date: appointment.startDateTime,
  //     id: appointment.id!
  //   });
  //   const newStatusChanges = [...updatedAppointment.statusChanges];
  //   newStatusChanges.push(
  //     new AppointmentStatusChange({
  //       status: AppointmentStatus.Canceled,
  //       date: new Date(),
  //       editorId: currentUser!.uid,
  //       editorType: currentUser!.userType,
  //       details: "Appointment Deletion"
  //     })
  //   );
  //   updatedAppointment.statusChanges = newStatusChanges;
  //   await appointmentService.updateAppointment(updatedAppointment);
  //   // create billableEvent and billableItem, then submit charge
  //   await AppointmentTransactionService.createBillableFromApptStatus({
  //     appointmentId: appointment!.id,
  //     placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
  //     statusChange: AppointmentStatus.Canceled,
  //     editorId: currentUser!.uid,
  //     approveImmediately: false
  //   });
  // };

  const dialogHeader =
    action === "Completed"
      ? "Begin Appointment"
      : action === "Cancelled"
      ? "Cancel Appointment"
      : action === "No-show"
      ? "Client No-show"
      : // : action === "Delete"
        // ? "Delete appointment"
        "[ERROR]";
  const dialogBody =
    action === "Completed"
      ? "Start this appointment and process payment for the barber.  "
      : action === "Cancelled"
      ? "Mark this appointment as cancelled and submit barber's cancellation payment to the Case Manager.  "
      : action === "No-show"
      ? "Mark this appointment as no-show and submit barber's no-show payment to the Case Manager.  "
      : // : action === "Delete"
        // ? "Delete this appointment.  Cancellation payment will be submitted for approval to Case Manager"
        "[ERROR]";
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {dialogHeader}
          </AlertDialogHeader>

          <AlertDialogBody>
            {dialogBody} You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              onClick={confirmAction}
              ml={3}
              isDisabled={loading}
            >
              {!loading ? "Confirm" : <Spinner />}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

import { Medication } from "@oben-core-web/models/medication";
import { MedicationService } from "@oben-core-web/services/medication-service";
import { useEffect, useState, useCallback } from "react";

const useMedications = ({
  clientId,
  carePlanId
}: {
  carePlanId: string;
  clientId: string;
}) => {
  const [medications, setMedications] = useState<Medication[]>([]);
  const fetchMedications = useCallback(async () => {
    if (carePlanId && clientId) {
      const medicationService = new MedicationService();
      const meds = await medicationService.getClientMedications(
        clientId,
        carePlanId
      );
      setMedications(
        meds.sort((a, b) => {
          // ignore upper and lowercase
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      );
    }
  }, [carePlanId, clientId]);
  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);
  return { medications, refetch: fetchMedications };
};

export default useMedications;

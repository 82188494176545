import {
  collectionGroup,
  collection,
  DocumentData,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
  orderBy,
  setDoc
} from "firebase/firestore";
import { Database } from "../../firebase";

import { ClientTask } from "../models/client-task";
import { GaLog } from "../utils/ga-log";

export class ClientTaskService {
  private _grandParentCollectionName: string;
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._grandParentCollectionName = "clients";
    this._parentCollectionName = "carePlans";
    this._collectionName = "clientTasks";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // Get all tasks for a specified care plan (for an individual client)
  async getClientTasks(
    clientId: string,
    carePlanId: string
  ): Promise<ClientTask[]> {
    const path = `${this._grandParentCollectionName}/${clientId}/${this._parentCollectionName}/${carePlanId}/${this._collectionName}`;
    try {
      const tasksQuery = query(
        collection(Database, path),
        orderBy("createdDate", "desc")
      );
      const qSnapshot = await getDocs(tasksQuery);
      GaLog.readCollection(path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) =>
        ClientTask.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(path, error);
      throw error;
    }
  }

  async getTask(clientTaskId: string): Promise<ClientTask> {
    try {
      const taskQuery = query(
        this._collectionGroup,
        where("modelId", "==", clientTaskId)
      );
      const qSnapshot = await getDocs(taskQuery);
      if (qSnapshot.empty) {
        throw new Error(`ClientTask not found: ${clientTaskId}`);
      }
      const task = ClientTask.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(
        `${this._grandParentCollectionName}/${task.clientId}/${this._parentCollectionName}/${task.carePlanId}/${this._collectionName}`,
        task.modelId
      );
      return task;
    } catch (error) {
      GaLog.readError(
        `${this._grandParentCollectionName}/?????/${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  async addTask(clientTask: ClientTask): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${clientTask.clientId}/${this._parentCollectionName}/${clientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef);
      await setDoc(docRef, { ...clientTask.toJson(), modelId: docRef.id });
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateTask(clientTask: ClientTask): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${clientTask.clientId}/${this._parentCollectionName}/${clientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, clientTask.modelId);
      await updateDoc(docRef, clientTask.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteTask(clientTask: ClientTask): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._grandParentCollectionName}/${clientTask.clientId}/${this._parentCollectionName}/${clientTask.carePlanId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, clientTask.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, clientTask.modelId);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}

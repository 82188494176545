import useClientTasks from "@/hooks/useTasks";
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Center,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import {
  ClientTask,
  ClientTaskType,
  IClientTaskData
} from "@oben-core-web/models/client-task";
import _ from "lodash";
import { DateTime } from "luxon";
import BPChart from "../molecules/BPChart";
import MedicationChart from "../molecules/MedicationChart";
import MedAdherenceChart from "../molecules/MedAdherenceChart";
import {
  TbCircleCheck,
  TbCircleX,
  TbEdit,
  TbEditCircle,
  TbParkingCircle
} from "react-icons/tb";

import { FreqPeriod } from "@oben-core-web/constants/core-enums";
import { ClientTaskService } from "@oben-core-web/services/client-task-service";
import {
  TaskStatus,
  TaskStatusChange
} from "@oben-core-web/models/task-status-change";
import { useForm } from "react-hook-form";
import { calculateExpectedCount } from "@/lib/clientTasks";
import { useState } from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import useTaskBase from "@/hooks/useTaskBase";
import useClientUser from "@/hooks/useClientUser";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

interface ICarePlanDetails {
  clientId: string;
  carePlanId: string;
}

const CarePlanDetails = ({
  clientId,
  carePlanId
}: // toggleEditModal
ICarePlanDetails) => {
  const healthcareActionModal = useDisclosure();
  const smartGoalModal = useDisclosure();
  const [showAdherenceChart, setShowAdherenceChart] = useState(false);
  const { clientUser } = useClientUser(clientId);
  const clientTasks = useClientTasks({ clientId, carePlanId });
  const groupedTasks = _.groupBy(clientTasks, (task) => {
    return task.taskType;
  });
  const healthMaintenanceTasks = _.pick(groupedTasks, [
    ClientTaskType.HealthcareAction,
    ClientTaskType.HomeEducation
  ]);
  const healthMaintenanceTasksByCategory = _.groupBy(
    Object.values(healthMaintenanceTasks).flat(),
    (t) => t.taskCategory
  );
  const smartGoalTasksByCategory = _.groupBy(
    groupedTasks[ClientTaskType.SmartGoal],
    (task) => {
      const category = task.taskCategory;
      return category;
    }
  );
  const followUpTasks = _.pick(groupedTasks, [ClientTaskType.HomeBPMonitoring]);
  return (
    <Flex direction='column' bg={"white"} p={4} h={"full"}>
      {clientUser && (
        <Breadcrumb
          alignSelf={"flex-start"}
          fontWeight={"normal"}
          mb={2}
          separator={<ChevronRightIcon color='gray.500' />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/patient/${clientUser.uid}`}>
              {clientUser.name.display}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link}>Manage Careplan</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
      <BPChart
        patientId={clientId}
        height={250}
        width={"100%"}
        canEditGoal={true}
      />
      {showAdherenceChart ? (
        <MedAdherenceChart
          patientId={clientId}
          toggleChart={() => setShowAdherenceChart((c) => !c)}
          height={250}
        />
      ) : (
        <MedicationChart
          patientId={clientId}
          toggleChart={() => setShowAdherenceChart((c) => !c)}
          height={250}
        />
      )}
      {healthMaintenanceTasks && (
        <Flex direction='column' mt={2}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize='x-large'>Your Healthcare Actions</Text>
            <Button
              variant={"link"}
              size='sm'
              onClick={healthcareActionModal.onToggle}
            >
              Add Task
            </Button>
          </Flex>
          {_.toPairs(healthMaintenanceTasksByCategory).map(([type, tasks]) => {
            return (
              <Card
                direction='column'
                variant={"outline"}
                key={`hm-task-category-${type}`}
                rounded={"md"}
                p={2}
                mb={2}
              >
                <Text fontSize='large' as='b'>
                  {type === "Unknown"
                    ? "General"
                    : type === "PrimaryCareAgenda"
                    ? "Primary Care Agenda"
                    : type}
                </Text>
                {tasks.map((task) => {
                  const latestStatus = getLastTaskStatus(task);
                  return (
                    <Flex
                      key={`care-plan-hm-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Box>
                        {new Date() > task.dueDate! &&
                        task.expectedCount > task.taskCompletionDates.length
                          ? TaskStatusBadges.Overdue
                          : TaskStatusBadges[latestStatus?.status ?? "New"]}
                        <TaskModal task={task} />
                      </Box>
                    </Flex>
                  );
                })}
              </Card>
            );
          })}
        </Flex>
      )}
      {smartGoalTasksByCategory && (
        <Flex direction='column' mt={2}>
          <Flex
            direction='row'
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize='x-large'>Your Smart Goals</Text>
            <Button
              variant={"link"}
              size='sm'
              onClick={smartGoalModal.onToggle}
            >
              Add Task
            </Button>
          </Flex>
          {_.toPairs(smartGoalTasksByCategory).map(([type, tasks]) => (
            <Card
              variant={"outline"}
              direction='column'
              key={`sg-task-category-${type}`}
              mb={2}
              p={2}
            >
              <Text fontSize='large' as='b'>
                {type}
              </Text>
              {tasks.map((task) => {
                const latestStatus = getLastTaskStatus(task);
                let statusDetail: any = "";
                const percentageAvailable = isNaN(
                  task.taskCompletionDates.length / task.expectedCount
                );
                if (
                  latestStatus.status !== TaskStatus.New &&
                  latestStatus.status !== TaskStatus.InProgress
                ) {
                  statusDetail = TaskStatusBadges[latestStatus.status];
                } else if (!percentageAvailable) {
                  statusDetail = (
                    <Text>
                      {Math.round(
                        (task.taskCompletionDates.length / task.expectedCount) *
                          100
                      )}
                      % Complete
                    </Text>
                  );
                } else {
                  statusDetail = (
                    <Text fontStyle={"italic"} fontWeight={"normal"}>
                      Status Unavailable
                    </Text>
                  );
                }
                return (
                  <Flex
                    key={`care-plan-sg-task-${task.modelId}`}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      fontWeight: "normal",
                      _hover: { fontWeight: "bold" }
                    }}
                  >
                    <Text>{task.name}</Text>
                    <Flex alignItems={"center"}>
                      {statusDetail}
                      <TaskModal task={task} />
                    </Flex>
                  </Flex>
                );
              })}
            </Card>
          ))}
        </Flex>
      )}
      {Object.values(followUpTasks).length > 0 && (
        <>
          <Text fontSize='x-large'>Your Follow Up Tasks</Text>
          <Card direction='column' mb={2} p={2}>
            {Object.values(followUpTasks).map((tasks) =>
              tasks.map((task) => {
                const isRecurringTask =
                  task.frequencyPeriod !== FreqPeriod.Once &&
                  task.frequencyPeriod !== FreqPeriod.Unknown;
                if (isRecurringTask) {
                  return (
                    <Flex
                      key={`care-plan-sg-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Flex>
                        <Text>
                          {Math.round(
                            (task.taskCompletionDates.length /
                              task.expectedCount) *
                              100
                          )}
                          % Complete
                        </Text>
                        <TaskModal task={task} />
                      </Flex>
                    </Flex>
                  );
                } else {
                  const latestStatus = getLastTaskStatus(task);
                  return (
                    <Flex
                      key={`care-plan-hm-task-${task.modelId}`}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        fontWeight: "normal",
                        _hover: { fontWeight: "bold" }
                      }}
                    >
                      <Text>{task.name}</Text>
                      <Box>
                        {new Date() < task.dueDate! &&
                        task.expectedCount > task.taskCompletionDates.length
                          ? TaskStatusBadges.Overdue
                          : TaskStatusBadges[latestStatus?.status ?? "New"]}
                        <TaskModal task={task} />
                      </Box>
                    </Flex>
                  );
                }
              })
            )}
          </Card>
        </>
      )}
      <HealthActionTaskForm
        clientId={clientId}
        carePlanId={carePlanId}
        isOpen={healthcareActionModal.isOpen}
        toggleForm={healthcareActionModal.onToggle}
      />
      <SmartGoalTaskForm
        clientId={clientId}
        carePlanId={carePlanId}
        isOpen={smartGoalModal.isOpen}
        toggleForm={smartGoalModal.onToggle}
      />
    </Flex>
  );
};

export default CarePlanDetails;

const getLastTaskStatus = (task: ClientTask) => {
  return (
    task.statusChanges?.sort((a, b) => {
      return a.date <= b.date ? 1 : -1;
    })[0] ?? null
  );
};

const TaskModal = ({ task }: { task: ClientTask }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: editFormOpen, onToggle: editFormToggle } = useDisclosure();
  const toast = useToast();
  const isRecurringTask =
    task.frequencyPeriod !== FreqPeriod.Once &&
    task.frequencyPeriod !== FreqPeriod.Unknown;
  const currentTaskStatus = getLastTaskStatus(task);
  const updateTaskStatus = async (status: TaskStatus) => {
    const clientTaskService = new ClientTaskService();
    const updatedTask = task;
    // create a new task status change
    updatedTask.statusChanges.push(
      new TaskStatusChange({ status, date: new Date() })
    );
    if (
      status === TaskStatus.Canceled ||
      status === TaskStatus.Finished ||
      status === TaskStatus.OnHold
    ) {
      // if task is stopped early for any reason, update expected count to current completion count -- expected count will be updated later if it is ever restarted (only in case of OnHold)
      updatedTask.expectedCount = task.taskCompletionDates.length;
    }
    await clientTaskService.updateTask(updatedTask).then(() => {
      toast({ status: "success", description: "Task updated successfully" });
      onToggle();
    });
  };
  const frequencyLabel = isRecurringTask
    ? task.frequencyCount === 1
      ? "Once " +
        task.frequencyPeriod.toLowerCase() +
        ` until ${DateTime.fromJSDate(task.dueDate!).toFormat("MM/dd/yy")}`
      : task.frequencyCount === 2
      ? "Twice " +
        task.frequencyPeriod.toLowerCase() +
        ` until ${DateTime.fromJSDate(task.dueDate!).toFormat("MM/dd/yy")}`
      : task.frequencyCount +
        " time " +
        task.frequencyPeriod.toLowerCase() +
        ` until ${DateTime.fromJSDate(task.dueDate!).toFormat("MM/dd/yy")}`
    : `Due ${DateTime.fromJSDate(task.dueDate!).toFormat("MM/dd/yy")}`;
  return (
    <>
      <IconButton
        ml={".5rem"}
        aria-label='edit-task'
        icon={<TbEdit size={18} />}
        size='xs'
        variant='unstyled'
        onClick={() => {
          onToggle();
        }}
        cursor={"pointer"}
      ></IconButton>
      <Modal isOpen={isOpen} onClose={onToggle} size={"lg"}>
        <ModalOverlay />
        {editFormOpen ? (
          <TaskForm task={task} toggleForm={editFormToggle} />
        ) : (
          <ModalContent>
            <ModalHeader
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <VStack
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text lineHeight={"1rem"}>{task.name}</Text>
                <Text as={"i"} fontSize={"small"} fontWeight={"normal"}>
                  {frequencyLabel}
                </Text>
              </VStack>
              <VStack justifyContent={"space-between"} alignItems={"flex-end"}>
                <CloseButton h={3} w={3} onClick={onToggle} />
              </VStack>
            </ModalHeader>
            <ModalBody py={0}>
              <Text fontWeight={"normal"}>{task.description}</Text>
              {isRecurringTask ? (
                task.taskCompletionDates.length > 0 ? (
                  <VStack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    mt={2}
                    border={".5px solid"}
                    p={2}
                    rounded='md'
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      w={"full"}
                    >
                      <Text as='b'>Task Log</Text>
                      <Text color={"GrayText"} as={"i"} fontSize={"small"}>
                        {task.taskCompletionDates.length}/{task.expectedCount}{" "}
                        Complete
                      </Text>
                    </Flex>
                    {task.taskCompletionDates?.toReversed().map((date, i) => (
                      <Text
                        as={"i"}
                        lineHeight={"1em"}
                        key={`task-${task.modelId}-completion-${i}`}
                      >
                        Completed on{" "}
                        {DateTime.fromJSDate(date!).toFormat("MM/dd/yy")}
                      </Text>
                    ))}
                  </VStack>
                ) : (
                  <Center mt={2} bg={"gray.200"} p={4}>
                    <Text as={"i"} textAlign={"center"} color={"grey"}>
                      No task completions
                    </Text>
                  </Center>
                )
              ) : (
                <></>
              )}
            </ModalBody>
            <ModalFooter display={"flex"} justifyContent={"space-between"}>
              <Flex alignItems={"center"}>
                <Tooltip label='Edit this task' hasArrow>
                  <IconButton
                    icon={<TbEditCircle size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#4299e1" // Blue400
                      }
                    }}
                    onClick={editFormToggle}
                  />
                </Tooltip>
                <Tooltip
                  label={
                    currentTaskStatus.status === TaskStatus.Finished
                      ? "Task is already completed"
                      : "Mark as complete"
                  }
                  hasArrow
                >
                  <IconButton
                    icon={<TbCircleCheck size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    isDisabled={
                      currentTaskStatus.status === TaskStatus.Finished
                    }
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#38A169" // Green500
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.Finished)}
                  />
                </Tooltip>
                <Tooltip
                  label={
                    currentTaskStatus.status === TaskStatus.OnHold
                      ? "Task already on hold"
                      : "Place task on hold"
                  }
                  hasArrow
                >
                  <IconButton
                    icon={<TbParkingCircle size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    isDisabled={currentTaskStatus.status === TaskStatus.OnHold}
                    size={"xs"}
                    sx={{
                      _hover: {
                        color: "#D69E2E" // Yellow500
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.OnHold)}
                  />
                </Tooltip>
                <Tooltip
                  label={
                    currentTaskStatus.status === TaskStatus.Canceled
                      ? "Task already cancelled"
                      : "Cancel this task"
                  }
                  hasArrow
                >
                  <IconButton
                    icon={<TbCircleX size={18} />}
                    isRound
                    aria-label='edit-task'
                    variant={"unstyled"}
                    size={"xs"}
                    isDisabled={
                      currentTaskStatus.status === TaskStatus.Canceled
                    }
                    sx={{
                      _hover: {
                        color: "#F56565" // Red400
                      }
                    }}
                    onClick={() => updateTaskStatus(TaskStatus.Canceled)}
                  />
                </Tooltip>
              </Flex>
              {currentTaskStatus && TaskStatusBadges[currentTaskStatus.status]}
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

const TaskForm = ({
  task,
  toggleForm
}: {
  task: ClientTask;
  toggleForm: () => void;
}) => {
  const toast = useToast();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: task.name,
      description: task.description,
      dueDate: DateTime.fromJSDate(task.dueDate!).toFormat("yyyy-MM-dd")
    }
  });
  const lastTaskStatus = getLastTaskStatus(task);
  const submissionHandler = async (data: {
    name: string;
    description: string;
    dueDate: string;
  }) => {
    // TODO: Figure out why fields aren't dirty here
    // const { dirtyFields } = formState;
    // const { name, description, dueDate } = dirtyFields;
    // const hasDirtyFields = !!name || !!description || !!dueDate;
    // console.log({ dirtyFields });
    task.name = data.name;
    task.description = data.description;
    task.dueDate = new Date(data.dueDate);
    if (task.frequencyPeriod !== FreqPeriod.Once) {
      // if recurring task, recalculate expected from new dueDate
      task.expectedCount = calculateExpectedCount(
        task.createdDate ?? new Date(),
        new Date(data.dueDate),
        task.frequencyCount,
        task.frequencyPeriod
      );
    }
    if (lastTaskStatus.status === TaskStatus.OnHold) {
      task.statusChanges.push(
        new TaskStatusChange({
          status: TaskStatus.InProgress,
          date: new Date()
        })
      );
    }
    const clientTaskService = new ClientTaskService();
    await clientTaskService.updateTask(task).then(() => {
      toast({ status: "success", description: "Task updated!" });
      toggleForm();
    });
    // }
  };
  return (
    <ModalContent>
      <form onSubmit={handleSubmit(submissionHandler)}>
        <ModalHeader>Edit Task</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input {...register("name")} />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input {...register("description")} />
          </FormControl>
          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Input type={"date"} {...register("dueDate")} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleForm}>Cancel</Button>
          <Button type='submit'>Submit</Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

const TaskStatusBadges = {
  New: <Badge variant={"outline"}>Not Started</Badge>,
  InProgress: (
    <Badge variant={"outline"} colorScheme='blue'>
      In Progress
    </Badge>
  ),
  OnHold: (
    <Badge variant={"outline"} colorScheme='yellow'>
      On Hold
    </Badge>
  ),
  Finished: (
    <Badge variant={"outline"} colorScheme='green'>
      Complete
    </Badge>
  ),
  Canceled: <Badge variant={"outline"}>Canceled</Badge>,
  Overdue: (
    <Badge variant={"outline"} colorScheme='red'>
      Overdue
    </Badge>
  ),
  Unknown: <Badge variant={"outline"}>Unknown</Badge>
};

const HealthActionTaskForm = ({
  clientId,
  carePlanId,
  isOpen,
  toggleForm
}: {
  clientId: string;
  carePlanId: string;
  isOpen: boolean;
  toggleForm: () => void;
}) => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { taskBases } = useTaskBase({
    placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? ""
  });
  const bases = taskBases.filter(
    (tb) => tb.taskType === ClientTaskType.HealthcareAction
  );
  const { handleSubmit, register, setValue, reset } = useForm<
    Omit<IClientTaskData, "dueDate"> & { dueDate: string | null }
  >({
    defaultValues: {
      clientId,
      carePlanId,
      clientTaskBaseId: "",
      placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
      taskType: ClientTaskType.HealthcareAction,
      name: "",
      description: "",
      frequencyCount: 1,
      frequencyPeriod: FreqPeriod.Once,
      dueDate: null,
      creatorId: currentUser?.uid,
      creatorType: currentUser?.userType,
      expectedCount: 1,
      statusChanges: [
        new TaskStatusChange({
          date: new Date(),
          status: TaskStatus.New
        })
      ],
      taskCompletionDates: [],
      createdDate: null
    }
  });
  const submissionHandler = async (
    data: Omit<IClientTaskData, "dueDate"> & { dueDate: string | null }
  ) => {
    if (!data.dueDate) {
      toast({ status: "error", description: "Must enter a due date" });
      return;
    } else if (!currentUser) {
      return;
    }
    const clientTaskService = new ClientTaskService();
    await clientTaskService
      .addTask(
        new ClientTask({
          ...data,
          modelId: "",
          placeBasedCareProvId: currentUser.placeBasedCareProvId ?? "",
          creatorId: currentUser.uid,
          creatorType: currentUser.userType,
          createdDate: new Date(),
          dueDate: new Date(data.dueDate)
        })
      )
      .then(() => {
        toast({ status: "success", description: "Task created!" });
        reset();
        toggleForm();
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        toggleForm();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>Add Healthcare Action Task</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Select
                  placeholder={"Select a task"}
                  {...register(`clientTaskBaseId`)}
                  onChange={(e) => {
                    if (e.target.value) {
                      const specificTask = taskBases.find(
                        (tb) => tb.id === e.target.value
                      );
                      setValue(`taskCategory`, specificTask!.taskCategory);
                      setValue("name", specificTask!.name);
                      setValue("description", specificTask!.description);
                      setValue(
                        "dueDate",
                        DateTime.now()
                          .plus({ days: specificTask!.defaultDuration })
                          .toFormat("yyyy-MM-dd")
                      );
                    }
                  }}
                >
                  {bases.map((tb) => (
                    <option key={`task-id-${tb.id}`} value={tb.id}>
                      {tb.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea {...register("description")} />
              </FormControl>
              <FormControl>
                <FormLabel>Due Date</FormLabel>
                <Input type={"date"} {...register("dueDate")} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              onClick={() => {
                reset();
                toggleForm();
              }}
            >
              Cancel
            </Button>
            <Button type='submit' colorScheme='blue'>
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const SmartGoalTaskForm = ({
  clientId,
  carePlanId,
  isOpen,
  toggleForm
}: {
  clientId: string;
  carePlanId: string;
  isOpen: boolean;
  toggleForm: () => void;
}) => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { taskBases } = useTaskBase({
    placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? ""
  });
  const bases = taskBases.filter(
    (tb) => tb.taskType === ClientTaskType.SmartGoal
  );
  const { handleSubmit, register, setValue, reset } = useForm<
    Omit<IClientTaskData, "dueDate"> & { dueDate: string | null }
  >({
    defaultValues: {
      modelId: "",
      clientId,
      carePlanId,
      clientTaskBaseId: "",
      placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
      taskType: ClientTaskType.SmartGoal,
      name: "",
      description: "",
      frequencyCount: 0,
      frequencyPeriod: FreqPeriod.Daily,
      dueDate: null,
      creatorId: currentUser?.uid,
      creatorType: currentUser?.userType,
      expectedCount: 0,
      statusChanges: [
        new TaskStatusChange({
          date: new Date(),
          status: TaskStatus.New
        })
      ],
      taskCompletionDates: [],
      createdDate: null
    }
  });
  const submissionHandler = async (
    data: Omit<IClientTaskData, "dueDate"> & { dueDate: string | null }
  ) => {
    if (!data.dueDate) {
      toast({ status: "error", description: "Must enter a due date" });
      return;
    } else if (!currentUser) {
      return;
    }
    const clientTaskService = new ClientTaskService();
    await clientTaskService
      .addTask(
        new ClientTask({
          ...data,
          creatorId: currentUser.uid,
          creatorType: currentUser.userType,
          createdDate: new Date(),
          dueDate: new Date(data.dueDate),
          expectedCount: calculateExpectedCount(
            new Date(),
            new Date(data.dueDate),
            data.frequencyCount,
            data.frequencyPeriod
          )
        })
      )
      .then(() => {
        toast({ status: "success", description: "Task created!" });
        reset();
        toggleForm();
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        toggleForm();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader>Add Smart Goal Task</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Select
                  placeholder={"Select a task"}
                  {...register(`clientTaskBaseId`)}
                  onChange={(e) => {
                    if (e.target.value) {
                      const specificTask = taskBases.find(
                        (tb) => tb.id === e.target.value
                      );
                      setValue(`taskCategory`, specificTask!.taskCategory);
                      setValue("name", specificTask!.name);
                      setValue("description", specificTask!.description);
                      setValue(
                        "dueDate",
                        DateTime.now()
                          .plus({ days: specificTask!.defaultDuration })
                          .toFormat("yyyy-MM-dd")
                      );
                      setValue(
                        "frequencyCount",
                        specificTask!.defaultFreqCount
                      );
                      setValue(
                        "frequencyPeriod",
                        specificTask!.defaultFreqPeriod
                      );
                    }
                  }}
                >
                  {bases.map((tb) => (
                    <option key={`task-id-${tb.id}`} value={tb.id}>
                      {tb.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea {...register("description")} />
              </FormControl>
              <FormControl>
                <FormLabel>Repititions</FormLabel>
                <Input type='number' min={1} {...register("frequencyCount")} />
              </FormControl>
              <FormControl>
                <FormLabel>Period</FormLabel>
                <Select {...register("frequencyPeriod")}>
                  <option value={FreqPeriod.Daily}>Daily</option>
                  <option value={FreqPeriod.Weekly}>Weekly</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Due Date</FormLabel>
                <Input type={"date"} {...register("dueDate")} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              onClick={() => {
                reset();
                toggleForm();
              }}
            >
              Cancel
            </Button>
            <Button type='submit' colorScheme='blue'>
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

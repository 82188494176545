// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp, FirebaseApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
  initializeFirestore
} from "firebase/firestore";
import {
  getAuth,
  browserLocalPersistence,
  Auth as FirebaseAuth,
  connectAuthEmulator
} from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions as FirebaseCloudFunctions
} from "firebase/functions";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

let App: FirebaseApp;
let Database: Firestore;
let Auth: FirebaseAuth;
let CloudFunctions: FirebaseCloudFunctions;
if (getApps().length > 0) {
  App = getApp();
  Database = initializeFirestore(App, {});
  Auth = getAuth(App);
  CloudFunctions = getFunctions(App);
} else {
  // Initialize Firebase
  App = initializeApp(firebaseConfig);
  Database = getFirestore(App);
  Auth = getAuth();
  CloudFunctions = getFunctions(App);
}

if (
  window.location.hostname === "localhost" &&
  import.meta.env.VITE_USE_EMULATOR === "true"
) {
  console.log("USE EMULATOR");
  connectAuthEmulator(Auth, `http://localhost:9099`);
  connectFirestoreEmulator(Database, "localhost", 8080);
  connectFunctionsEmulator(CloudFunctions, "localhost", 5001);
}

Auth.setPersistence(browserLocalPersistence);

export { App, Database, Auth as Authentication, CloudFunctions };

export interface ISystemInfoData {
  platform: string;
  version: string;
  upgradeDate: Date | null;
}

export class SystemInfo {
  platform: string;
  version: string;
  upgradeDate: Date | null;

  constructor({ platform, version, upgradeDate }: ISystemInfoData) {
    this.platform = platform;
    this.version = version;
    this.upgradeDate = upgradeDate;
  }

  static fromMap(data: { [key: string]: any }): SystemInfo {
    return new SystemInfo({
      platform: data["platform"] ?? "",
      version: data["version"] ?? "",
      upgradeDate: data["upgradeDate"] ? new Date(data["upgradeDate"]) : null
    });
  }
  
  toJson(): { [key: string]: any } {
    return {
      "platform": this.platform,
      "version": this.version,
      "upgradeDate": this.upgradeDate?.toISOString() ?? null,
    };
  }
}

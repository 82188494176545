import React from "react";
import PatientTable from "../molecules/PatientTable";
import { useState } from "react";
import BPChart from "../molecules/BPChart";
import { ClientUser } from "@oben-core-web/models/client-user";
import { VStack } from "@chakra-ui/react";

const Patients = () => {
  const [selectedPatient, setSelectedPatient] = useState<ClientUser>();

  return (
    <VStack h={"full"} spacing={3}>
      <div
        style={{
          backgroundColor: "white",
          height: "45%",
          width: "100%",
          padding: ".5rem",
          paddingTop: "1rem",
          border: "1px solid lightgray",
          borderRadius: 7
        }}
      >
        <BPChart
          patientId={selectedPatient?.uid}
          width={"100%"}
          height={"100%"}
        />
      </div>
      <PatientTable selectPatient={setSelectedPatient} />
    </VStack>
  );
};

export default Patients;

import React from "react";
import useAuthListener from "../hooks/useAuthListener";
import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";
import { RouterProvider } from "react-router-dom";
import { useAuthContext } from "@/hooks/useAuthContext";

const App: React.FC = () => {
  useAuthListener(); // This hook should set up auth status listeners
  const {
    state: { isLoggedIn }
  } = useAuthContext();

  // Determine which router to use based on authentication status
  const router = isLoggedIn ? AppRouter : AuthRouter;

  return <RouterProvider router={router} />;
};

export default App;

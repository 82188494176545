import { Center } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <Center h={"full"} w={"full"}>
      404: Page not found
    </Center>
  );
};

export default NotFound;

import { BarberUser } from "@oben-core-web/models/barber-user";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { useState, useEffect, useCallback } from "react";

const useBarber = ({
  uid,
  initialFetch = false
}: {
  uid?: string;
  initialFetch: boolean;
}) => {
  const [barber, setBarber] = useState<BarberUser>();
  const [shouldFetch, setShouldFetch] = useState(initialFetch);
  const fetchBarber = useCallback(async (uid: string) => {
    const barberUserService = new BarberUserService();
    const barber = await barberUserService.getBarberUser(uid);
    setBarber(barber);
    setShouldFetch(false);
    return barber;
  }, []);
  useEffect(() => {
    shouldFetch && uid && fetchBarber(uid);
  }, [fetchBarber, shouldFetch, uid]);
  return { barber, fetchBarber };
};

export default useBarber;

import React, { useState, useEffect, useRef, useMemo } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  useHits,
  useSearchBox
} from "react-instantsearch";
import {
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  IconButton,
  Box,
  BoxProps
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface IIndexSearchProps extends BoxProps {
  searchIndex: string;
  placeholderText: string;
  onResultClick: (item: any) => void;
  prefilledText?: string;
  transformItems?: (items: any[]) => any[];
  hitComponent?: (item: any) => JSX.Element;
  onClearSelection?: () => void;
  filters?: string;
}

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID!,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY!
);

const IndexSearch: React.FC<IIndexSearchProps> = ({
  searchIndex,
  placeholderText,
  prefilledText = "",
  transformItems = (items) => items,
  onResultClick,
  hitComponent,
  filters,
  onClearSelection,
  ...rest
}) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  const [showHits, setShowHits] = useState(false);
  const [queryText, setQueryText] = useState(prefilledText);

  const handleResultClick = (item: any) => {
    setShowHits(false);
    onResultClick(item);
    setQueryText(item.label);
  };

  const clearSelection = () => {
    setShowHits(false);
    setQueryText("");
    onClearSelection && onClearSelection();
    searchBarRef.current?.focus();
  };
  const configureParams = useMemo(() => {
    return { hitsPerPage: 10, ...(filters ? { filters } : {}) };
  }, [filters]);
  return (
    <InstantSearch
      indexName={searchIndex}
      searchClient={searchClient}
      future={{ preserveSharedStateOnUnmount: false }}
    >
      <Configure {...configureParams} />
      <Box position='relative' {...rest}>
        <SearchBar
          searchBarRef={searchBarRef}
          placeholderText={placeholderText}
          query={queryText}
          setShowHits={setShowHits}
          onSelectionCleared={clearSelection}
        />
        {showHits && (
          <SearchHits
            transformItems={transformItems}
            handleResultClick={handleResultClick}
            CustomHit={hitComponent}
          />
        )}
      </Box>
    </InstantSearch>
  );
};

export default IndexSearch;

const SearchHits = ({
  transformItems,
  handleResultClick,
  CustomHit
}: {
  transformItems: (items: any[]) => any[];
  handleResultClick: (item: any) => void;
  CustomHit?: (item: any) => JSX.Element;
}) => {
  const { items } = useHits();
  const transformedHits = transformItems(items);
  return (
    <List
      spacing={3}
      mt={2}
      position='absolute'
      top='100%' // Position directly below the search bar
      left={0}
      width='100%' // Make the hits list the same width as the search bar
      bg='white'
      zIndex={1000}
    >
      {transformedHits.map((item) => {
        return (
          <ListItem
            sx={{
              _hover: {
                backgroundColor: "gray.50"
              }
            }}
            // key={item.objectID}
            key={`search-result-${item.id}-${item.name}`}
            onClick={() => handleResultClick(item)}
            cursor='pointer'
            padding={2}
          >
            {CustomHit ? <CustomHit item={item} /> : <b>{item.label}</b>}
          </ListItem>
        );
      })}
    </List>
  );
};

const SearchBar = ({
  placeholderText,
  query,
  setShowHits,
  searchBarRef,
  onSelectionCleared
}: {
  placeholderText: string;
  query: string;
  setShowHits: React.Dispatch<React.SetStateAction<boolean>>;
  searchBarRef: React.RefObject<HTMLInputElement>;
  onSelectionCleared: () => void;
}) => {
  const { refine } = useSearchBox();
  const [searchQuery, setLocalSearchQuery] = useState(query);

  useEffect(() => {
    setLocalSearchQuery(query);
  }, [query]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearchQuery(value);
    refine(value);
    setShowHits(!!e.target.value);
  };

  const clearSelection = () => {
    setLocalSearchQuery("");
    onSelectionCleared();
  };

  return (
    <InputGroup>
      <Input
        ref={searchBarRef}
        type='text'
        placeholder={placeholderText}
        value={searchQuery}
        onChange={handleInputChange}
        width='100%'
      />
      {searchQuery && (
        <InputRightElement>
          <IconButton
            icon={<CloseIcon />}
            onClick={clearSelection}
            aria-label='Clear Selection'
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

// import useCarePlan from '@/hooks/useCarePlan';
import useClientCarePlans from "@/hooks/useClientCarePlans";
import useClientUser from "@/hooks/useClientUser";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import CarePlanDetails from "../organisms/CarePlanDetails";

const CarePlan = () => {
  const { id, carePlanId } = useParams();
  const { clientUser } = useClientUser(id);
  const { carePlans } = useClientCarePlans(id);
  const carePlan = carePlans.find((cp) => cp.modelId === carePlanId);
  return (
    <Box>
      {!carePlan || !clientUser ? (
        <div>Loading...</div>
      ) : (
        <CarePlanDetails clientId={id!} carePlanId={carePlanId!} />
      )}
    </Box>
  );
};

export default CarePlan;

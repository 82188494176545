import { useMemo } from "react";
import { useAuthContext } from "./useAuthContext";

const useCurrentUser = () => {
  const {
    state: { currentUser }
  } = useAuthContext();

  return useMemo(() => ({ currentUser }), [currentUser]);
};

export default useCurrentUser;

import { useCallback, useEffect, useRef } from "react";
import useAuthentication from "./useAuthentication";

function useInactivityTimer(logoutTime: number = 10 * 60 * 1000) {
  const { logout } = useAuthentication();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logout, logoutTime);
  }, [logout, logoutTime]);

  useEffect(() => {
    // Register activity listeners
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    // Initialize timer on mount
    resetTimer();

    // Cleanup on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, [resetTimer]); // Empty dependency array ensures this effect only runs on mount and unmount

  return {
    resetTimer
  };
}

export default useInactivityTimer;

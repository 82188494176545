import { DocumentSnapshot } from "firebase/firestore";

import { AppointmentService } from "../services/appointment-service";
import { AppointmentStatusChange } from "./appointment-status-change";
import { UserName } from "./user-name";

export interface IAppointmentData {
  id: string;
  barbershopId: string;
  barbershopName: string;
  barberId: string;
  barberName: UserName;
  clientId: string;
  clientName: UserName;
  pharmacistId: string;
  pharmacistName: UserName;
  date: Date | null;
  length: number;
  billableEventId: string;
  statusChanges: AppointmentStatusChange[];
}

export class Appointment {
  id: string;
  barbershopId: string;
  barbershopName: string;
  barberId: string;
  barberName: UserName;
  clientId: string;
  clientName: UserName;
  pharmacistId: string;
  pharmacistName: UserName;
  date: Date | null;
  length: number;
  billableEventId: string;
  statusChanges: AppointmentStatusChange[];

  constructor({
    id,
    barbershopId,
    barbershopName,
    barberId,
    barberName,
    clientId,
    clientName,
    pharmacistId,
    pharmacistName,
    date,
    length,
    billableEventId,
    statusChanges
  }: IAppointmentData) {
    this.id = id;
    this.barbershopId = barbershopId;
    this.barbershopName = barbershopName;
    this.barberId = barberId;
    this.barberName = barberName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.pharmacistId = pharmacistId;
    this.pharmacistName = pharmacistName;
    this.date = date;
    this.length = length;
    this.billableEventId = billableEventId;
    this.statusChanges = statusChanges;
  }

  async updateDb(): Promise<void> {
    const db = new AppointmentService();
    await db.updateAppointment(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Appointment {
    const data = docSnap.data() as { [key: string]: any };
    return Appointment.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Appointment {
    return new Appointment({
      id: id,
      barbershopId: data["barbershopId"] ?? "",
      barbershopName: data["barbershopName"] ?? "",
      barberId: data["barberId"] ?? "",
      barberName: UserName.fromMap(data["barberName"] ?? {}),
      clientId: data["clientId"] ?? "",
      clientName: UserName.fromMap(data["clientName"] ?? {}),
      pharmacistId: data["pharmacistId"] ?? "",
      pharmacistName: UserName.fromMap(data["pharmacistName"] ?? {}),
      date: data["date"] ? new Date(data["date"]) : null,
      length: data["length"] ?? 0,
      billableEventId: data["billableEventId"] ?? "",
      statusChanges: data["statusChanges"] //
        ? data["statusChanges"].map((element: any) => AppointmentStatusChange.fromMap(element))
        : []
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "barbershopId": this.barbershopId,
      "barbershopName": this.barbershopName,
      "barberId": this.barberId,
      "barberName": this.barberName.toJson(),
      "clientId": this.clientId,
      "clientName": this.clientName.toJson(),
      "pharmacistId": this.pharmacistId,
      "pharmacistName": this.pharmacistName.toJson(),
      "date": this.date?.toISOString() ?? null,
      "length": Number(this.length),
      "billableEventId": this.billableEventId,
      "statusChanges": this.statusChanges.map((element) => element.toJson()),
    };
  }
}

import { CustomWorkingHours } from "@oben-core-web/models/custom-working-hours";
import { CustomWorkingHoursService } from "@oben-core-web/services/custom-working-hours-service";
import { useState, useEffect, useCallback, useRef } from "react";
import { convertToUTC } from "@/lib/timezone";
import isEqual from "lodash/isEqual";

const useCustomWorkingHours = (queryOptions?: {
  userId: string;
  start: Date;
  end: Date;
}) => {
  const [customWorkingHours, setCustomWorkingHours] = useState<
    CustomWorkingHours[]
  >([]);

  const prevQueryOptionsRef = useRef(queryOptions);

  const fetchCustomWorkingHours = useCallback(
    async ({
      userId,
      start,
      end
    }: {
      userId: string;
      start: Date;
      end: Date;
    }) => {
      const customWorkingHoursService = new CustomWorkingHoursService();
      const utcStart = convertToUTC(start);
      const utcEnd = convertToUTC(end);
      const result = await customWorkingHoursService.getStaffCustomWorkingHours(
        {
          userId,
          start: utcStart,
          end: utcEnd
        }
      );
      setCustomWorkingHours(result);
      return result;
    },
    []
  );

  useEffect(() => {
    if (!isEqual(prevQueryOptionsRef.current, queryOptions)) {
      prevQueryOptionsRef.current = queryOptions;

      if (queryOptions) {
        const { userId, start, end } = queryOptions;
        fetchCustomWorkingHours({
          userId,
          start: new Date(start),
          end: new Date(end)
        });
      }
    }
  }, [queryOptions, fetchCustomWorkingHours]);

  return { customWorkingHours, fetchCustomWorkingHours };
};

export default useCustomWorkingHours;

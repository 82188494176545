import { WebUserService } from "@oben-core-web/services/web-user-service";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Authentication } from "../../firebase";
import { useToast } from "@chakra-ui/react";
import { useAuthContext } from "./useAuthContext";

const useAuthentication = () => {
  const { beforeLogout } = useAuthContext();
  const toast = useToast();

  const login = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    if (!Authentication) {
      toast({
        status: "error",
        description: "Missing authentication provider"
      });
      return;
    }
    return signInWithEmailAndPassword(Authentication, email, password)
      .then(async (userCred) => {
        const wus = new WebUserService();
        const isValidUser = await wus.validateWebUser(userCred.user.uid);
        if (!isValidUser) {
          beforeLogout();
          signOut(Authentication);
          toast({
            description:
              "Unable to log in.  If you believe this is an error please contact the Oben team",
            status: "error"
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        throw err;
      });
  };

  const logout = async () => {
    if (!Authentication) {
      console.log("Logout error: No authentication");
      return;
    }
    beforeLogout();
    return await signOut(Authentication)
      .then(() => {
        console.log("Signed out");
      })
      .catch(() => {
        console.log("Unable to sign out");
      });
  };

  return { login, logout };
};

export default useAuthentication;

import { ASCVDCoefficients } from '../models/ascvd-coefficients';

export class ASCVDRiskCoefficients {
  // Define the coefficients for different risk factors based on sex and race
  static readonly coefficients: { [key: string]: ASCVDCoefficients } = {
    'Male_White': new ASCVDCoefficients({
      age: 12.344,
      ageSquared: -0.00016,
      totalCholesterol: 11.853,
      totalCholesterolAge: -2.664,
      hdlCholesterol: -7.990,
      hdlCholesterolAge: 1.769,
      sbpTreated: 1.797,
      sbpUntreated: 1.764,
      smoking: 7.837,
      smokingAge: -1.795,
      diabetes: 0.658,
    }),
    'Female_White': new ASCVDCoefficients({
      age: -29.799,
      ageSquared: 4.884,
      totalCholesterol: 13.540,
      totalCholesterolAge: -3.114,
      hdlCholesterol: -13.578,
      hdlCholesterolAge: 3.149,
      sbpTreated: 2.019,
      sbpUntreated: 1.957,
      smoking: 7.574,
      smokingAge: -1.665,
      diabetes: 0.661,
    }),
    'Male_Black': new ASCVDCoefficients({
      age: 2.469,
      ageSquared: 0.0,
      totalCholesterol: 0.302,
      totalCholesterolAge: 0.0,
      hdlCholesterol: -0.307,
      hdlCholesterolAge: 0.0,
      sbpTreated: 1.998, // TODO: check this value. It is not in the original paper (1.916)
      sbpUntreated: 1.933, // TODO: check this value. It is not in the original paper (1.809)
      smoking: 0.549,
      smokingAge: 0.0,
      diabetes: 0.645,
    }),
    'Female_Black': new ASCVDCoefficients({
      age: 17.114,
      ageSquared: 0.0,
      totalCholesterol: 0.940,
      totalCholesterolAge: 0.0,
      hdlCholesterol: -18.920,
      hdlCholesterolAge: 4.475,
      sbpTreated: 2.812, // TODO: check this value. It is not in the original paper (29.291)
      sbpUntreated: 2.882, // TODO: check this value. It is not in the original paper (27.819)
      smoking: 0.691,
      smokingAge: 0.0,
      diabetes: 0.874,
    }),
  };

  // Baseline survival rates and mean coefficients for different groups
  static readonly baselineSurvival: { [key: string]: number } = {
    'Male_White': 0.91436,
    'Female_White': 0.96652,
    'Male_Black': 0.89536,
    'Female_Black': 0.95334,
  };

  static readonly meanCoefficient: { [key: string]: number } = {
    'Male_White': 61.1816,
    'Female_White': -29.1817,
    'Male_Black': 19.5425,
    'Female_Black': 86.6081,
  };
}

import { useEffect, useState } from "react";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { WebUser } from "@oben-core-web/models/web-user";

const useWebUser = (id?: string) => {
  const [user, setUser] = useState<WebUser | null>(null);

  useEffect(() => {
    const webUserService = new WebUserService();
    const { unsubscribe } = webUserService.streamWebUser(
      id ?? "",
      (docSnap) => {
        if (docSnap.exists()) {
          setUser(WebUser.fromFirestore(docSnap));
        } else {
          setUser(null);
        }
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe && unsubscribe();
      }
    };
  }, [id]);

  return { user };
};

export default useWebUser;

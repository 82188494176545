import {
  collection,
  collectionGroup,
  DocumentData,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  Query,
  setDoc
} from "firebase/firestore";
import { Database } from "../../firebase";

import { BillableItem } from "../models/billable-item";
import { GaLog } from "../utils/ga-log";

export class BillableItemService {
  private _parentCollectionName: string;
  private _collectionName: string;
  private _collectionGroup: Query<DocumentData, DocumentData>;

  constructor() {
    this._parentCollectionName = "billableEvents";
    this._collectionName = "billableItems";
    this._collectionGroup = collectionGroup(Database, this._collectionName);
  }

  // get all billable items within a specified date range. default is current month (to date).
  async getAllBillableItems({
    placeBasedCareProvId,
    start,
    end
  }: {
    placeBasedCareProvId: string;
    start: Date | null;
    end: Date | null;
  }): Promise<BillableItem[]> {
    const now = new Date();
    const startOfRange =
      start || new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfRange = end || now;
    const itemsQuery = query(
      this._collectionGroup,
      where("placeBasedCareProvId", "==", placeBasedCareProvId),
      where("serviceDate", ">=", startOfRange.toISOString()),
      where("serviceDate", "<", endOfRange.toISOString())
    );
    try {
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  // Get all billable items for a specified client
  async getClientBillableItems(clientId: string): Promise<BillableItem[]> {
    try {
      const itemsQuery = query(
        this._collectionGroup,
        where("clientId", "==", clientId)
      );
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        qSnapshot.docs.length
      );
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  // Get all billable items for a specified billable event
  async getEventBillableItems(
    billableEventIdventId: string
  ): Promise<BillableItem[]> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${billableEventIdventId}/${this._collectionName}`
    );
    try {
      const itemsQuery = query(subcollectionRef);
      const qSnapshot = await getDocs(itemsQuery);
      GaLog.readCollection(subcollectionRef.path, qSnapshot.docs.length);
      return qSnapshot.docs.map((docSnap: any) =>
        BillableItem.fromFirestore(docSnap)
      );
    } catch (error) {
      GaLog.readError(subcollectionRef.path, error);
      throw error;
    }
  }

  async getBillableItem(itemId: string): Promise<BillableItem> {
    try {
      const itemQuery = query(
        this._collectionGroup,
        where("modelId", "==", itemId)
      );
      const qSnapshot = await getDocs(itemQuery);
      if (qSnapshot.empty) {
        throw new Error(`Billable Item not found: ${itemId}`);
      }
      const item = BillableItem.fromFirestore(qSnapshot.docs[0]);
      GaLog.readDocument(
        `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`,
        item.modelId
      );
      return item;
    } catch (error) {
      GaLog.readError(
        `${this._parentCollectionName}/?????/${this._collectionName}`,
        error
      );
      throw error;
    }
  }

  async addBillableItem(item: BillableItem): Promise<string> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef);
      await setDoc(docRef, { ...item.toJson(), modelId: docRef.id });
      GaLog.addDocument(subcollectionRef.path, docRef.id);
      return docRef.id;
    } catch (error) {
      GaLog.addError(subcollectionRef.path, error);
      throw error;
    }
  }

  async updateBillableItem(item: BillableItem): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, item.modelId);
      await updateDoc(docRef, item.toJson());
      GaLog.updateDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.updateError(subcollectionRef.path, error);
      throw error;
    }
  }

  async deleteBillableItem(item: BillableItem): Promise<void> {
    const subcollectionRef = collection(
      Database,
      `${this._parentCollectionName}/${item.billableEventId}/${this._collectionName}`
    );
    try {
      const docRef = doc(subcollectionRef, item.modelId);
      await deleteDoc(docRef);
      GaLog.deleteDocument(subcollectionRef.path, docRef.id);
      return;
    } catch (error) {
      GaLog.deleteError(subcollectionRef.path, error);
      throw error;
    }
  }
}

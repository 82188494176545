import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import usePatients from "@/hooks/usePatients";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { ClientUser } from "@oben-core-web/models/client-user";

interface IPatientTable {
  selectPatient: (patient: ClientUser) => void;
}

const PatientTable = ({ selectPatient }: IPatientTable) => {
  const { clientUsers } = usePatients();

  const patients = clientUsers.map((cu) => ({
    ...cu,
    id: cu.uid,
    name: cu.name ? cu.name?.first + " " + cu.name?.last : "Unnamed",
    ...(cu.dob
      ? { dob: DateTime.fromISO(cu.dob).toFormat("DD") }
      : // ? { dob: DateTime.fromJSDate(new Date(cu.dob)).toFormat("DD") }
        {}),
    ...(cu.registrationDate
      ? {
          duration: DateTime.fromJSDate(
            new Date(cu.registrationDate)
          ).toRelative()
        }
      : {}),
    ...(cu.registrationDate
      ? {
          registrationDate: DateTime.fromJSDate(
            new Date(cu.registrationDate)
          ).toFormat("DD")
        }
      : {}),
    ...(cu.currentBpReading
      ? {
          lastBp:
            cu.currentBpReading.systolic + "/" + cu.currentBpReading.diastolic
        }
      : {})
  }));

  const LinkToDetail = (params: any) => {
    return (
      <Link to={`/patient/${params.data.id}`}>
        <MdOutlineChevronRight
          size={24}
          style={{ alignSelf: "center", height: "100%" }}
        />
      </Link>
    );
  };

  const columnHeaders = [
    { field: "name" },
    { field: "dob" },
    { field: "registrationDate" },
    { field: "lastAppt" },
    { field: "lastBp" },
    { field: "engagementStatus" },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: LinkToDetail,
      sortable: false,
      filter: false
    }
  ];

  const handleRowSelected = (row: any) => {
    selectPatient(row.data);
  };

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        rowData={patients}
        columnDefs={columnHeaders as any}
        rowSelection='single'
        onRowSelected={handleRowSelected}
        autoSizeStrategy={{ type: "fitProvidedWidth", width: 1215 }}
      />
    </div>
  );
};

export default PatientTable;

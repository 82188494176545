import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Avatar,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack
} from "@chakra-ui/react";
import useAuthentication from "@/hooks/useAuthentication";
import useCurrentUser from "@/hooks/useCurrentUser";

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { logout } = useAuthentication();

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <MenuButton
        as={Box}
        display='flex'
        alignItems='center'
        cursor='pointer'
        flexDir={"row"}
        zIndex={10}
        p={2}
        px={3.5}
        rounded={8}
        onClick={() => setIsOpen(!isOpen)}
      >
        <HStack>
          {currentUser && (
            <Avatar
              name={`${currentUser.name?.first} ${currentUser.name?.last}`}
            />
          )}
          <VStack align='start' ml={2} spacing={0}>
            <Text fontWeight='bold'>
              {currentUser?.name
                ? `${currentUser.name.first} ${currentUser.name.last}`
                : "User"}
            </Text>
            <Text fontSize='sm' color='gray.500'>
              {currentUser?.userType}
            </Text>
          </VStack>
        </HStack>
      </MenuButton>
      <MenuList zIndex={20}>
        <MenuItem onClick={() => navigate(`/profile/${currentUser?.uid}`)}>
          My Profile
        </MenuItem>
        <MenuItem onClick={logout}>Log Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;

import { DocumentSnapshot } from "firebase/firestore";

import { UserType, UserTypeHelper } from "../constants/core-enums";
import { MedAdherenceService } from "../services/med-adherence-service";

/// MedAdherence records are maintained in a subcollection on CarePlan.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IMedAdherenceData {
  modelId: string;
  clientId: string;
  carePlanId: string;
  medicationId: string;
  medicationBaseId: string;
  placeBasedCareProvId: string;
  authorId: string;
  authorType: UserType;
  questionDate: Date | null;
  response: string;
}

export class MedAdherence {
  modelId: string;
  clientId: string;
  carePlanId: string;
  medicationId: string;
  medicationBaseId: string;
  placeBasedCareProvId: string;
  authorId: string;
  authorType: UserType;
  questionDate: Date | null;
  response: string;

  constructor({
    modelId,
    clientId,
    carePlanId,
    medicationId,
    medicationBaseId,
    placeBasedCareProvId,
    authorId,
    authorType,
    questionDate,
    response
  }: IMedAdherenceData) {
    this.modelId = modelId;
    this.clientId = clientId;
    this.carePlanId = carePlanId;
    this.medicationId = medicationId;
    this.medicationBaseId = medicationBaseId;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.authorId = authorId;
    this.authorType = authorType;
    this.questionDate = questionDate;
    this.response = response;
  }
  async updateDb(): Promise<void> {
    const db = new MedAdherenceService();
    await db.updateMedAdherence(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): MedAdherence {
    const data = docSnap.data() as { [key: string]: any };
    return MedAdherence.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): MedAdherence {
    return new MedAdherence({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      clientId: data["clientId"] ?? "",
      carePlanId: data["carePlanId"] ?? "",
      medicationId: data["medicationId"] ?? "",
      medicationBaseId: data["medicationBaseId"] ?? "",
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      authorId: data["authorId"] ?? "",
      authorType:
        UserTypeHelper.valueOf(data["authorType"] ?? "") ?? UserType.Unknown,
      questionDate: data["questionDate"]
        ? new Date(data["questionDate"])
        : null,
      response: data["response"] ?? ""
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId, // with subcollections we must save ID within the Firestore document itself
      clientId: this.clientId,
      carePlanId: this.carePlanId,
      medicationId: this.medicationId,
      medicationBaseId: this.medicationBaseId,
      placeBasedCareProvId: this.placeBasedCareProvId,
      authorId: this.authorId,
      authorType: this.authorType,
      questionDate: this.questionDate?.toISOString() ?? null,
      response: this.response
    };
  }
}

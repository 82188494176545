import React from "react";
import {
  VStack,
  HStack,
  Avatar,
  Heading,
  Text,
  Card,
  SimpleGrid
} from "@chakra-ui/react";
import { ClientUser } from "@oben-core-web/models/client-user";
import { DateTime } from "luxon";
import PatientProfileForm from "./PatientProfileForm";

interface IPatientProfile {
  patient: ClientUser;
}

const PatientProfile = ({ patient }: IPatientProfile) => {
  return (
    <HStack
      align='center'
      justify='space-between'
      spacing={4}
      minH={"18rem"}
      bg={"gray.50"}
      mb={4}
    >
      <Card w='25%' h={"18rem"}>
        <VStack align='center' justify='center' spacing={4} h={"full"}>
          <Avatar size='2xl' bg='blue.500' />
          <Heading as='h1' size='lg'>
            {patient.name?.display}
          </Heading>
          {patient.phoneNumber && <Text>{patient.phoneNumber}</Text>}
          {patient.email && <Text>{patient.email}</Text>}
        </VStack>
      </Card>
      <Card w='75%' h={"18rem"} justifyContent={"center"} px={8} gap={4}>
        <PatientProfileForm clientId={patient.uid} />
        <SimpleGrid columns={3} rowGap={12}>
          <VStack align='start'>
            <Text fontSize='small'>Sex</Text>
            <Text as={"b"} casing={"capitalize"}>
              {patient.sex}
            </Text>
          </VStack>
          <VStack align='start'>
            <Text fontSize='small'>Ethnicity</Text>
            <Text as={"b"}>Black</Text>
            {/* <Text as={"b"}>{patient.ethnicities?.[0]}</Text> */}
          </VStack>
          <VStack align='start'>
            <Text fontSize='small'>Date of Birth</Text>
            {patient.dob && (
              <Text as={"b"}>
                {DateTime.fromJSDate(new Date(patient.dob)).toFormat("DD")}
              </Text>
            )}
          </VStack>
          <VStack align='start'>
            <Text fontSize='small'>Physician</Text>
            <Text as={"b"}>Tem Woldeyesus</Text>
            {/* <Text as={"b"}>[physician name]</Text> */}
          </VStack>
          <VStack align='start'>
            <Text fontSize='small'>Barbershop</Text>
            <Text as={"b"}>Oben Barbershop</Text>
            {/* <Text as={"b"}>[barbershop name]</Text> */}
          </VStack>
          <VStack align='start'>
            <Text fontSize='small'>Last Active</Text>
            <Text as={"b"}>
              {patient.lastActivityDate
                ? DateTime.fromJSDate(patient.lastActivityDate).toFormat("DDD")
                : ""}
            </Text>
          </VStack>
        </SimpleGrid>
      </Card>
    </HStack>
  );
};

export default PatientProfile;

import React from "react";
import { IconProps, Tooltip } from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

interface IHoverHelp extends IconProps {
  label: string;
  hasArrow?: boolean;
}

const HoverHelp = ({ label, hasArrow = true, ...iconProps }: IHoverHelp) => {
  return (
    <Tooltip label={label} hasArrow={hasArrow}>
      <QuestionOutlineIcon {...iconProps} />
    </Tooltip>
  );
};

export default HoverHelp;

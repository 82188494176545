import { WebUser } from "@oben-core-web/models/web-user";

// Define action types
type TAuthAction =
  | { type: "ON_LOGIN"; payload: { currentUser: WebUser } }
  | { type: "BEFORE_LOGOUT" }
  | {
      type: "SET_DETACH_LISTENER";
      payload: { detachAuthListener: () => void };
    };

// Define the Auth state structure
export interface IAuthState {
  currentUser: WebUser | null;
  detachAuthListener: (() => void) | undefined;
  isLoggedIn: boolean;
}

// Initial state
export const initialState: IAuthState = {
  currentUser: null,
  detachAuthListener: undefined,
  isLoggedIn: false
};

// Reducer function
export const authReducer = (
  state: IAuthState,
  action: TAuthAction
): IAuthState => {
  switch (action.type) {
    case "ON_LOGIN":
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isLoggedIn: true
      };
    case "BEFORE_LOGOUT":
      // Optionally call the detachAuthListener before logging out
      state.detachAuthListener?.();
      return initialState;
    case "SET_DETACH_LISTENER":
      return {
        ...state,
        detachAuthListener: action.payload.detachAuthListener
      };
    default:
      return state;
  }
};

import { Grid, GridItem } from "@chakra-ui/react";
import AppHeader from "./components/organisms/AppHeader";
import NavSidebar from "./components/organisms/NavSidebar";
import { Outlet } from "react-router-dom";
import useInactivityTimer from "./hooks/useInactivityTimer";

// Stretch TODO: view barbers and their payouts
function App() {
  useInactivityTimer();
  return (
    <Grid
      templateAreas={`"nav header"
      "nav main"`}
      gridTemplateRows={"4.5rem 1fr"}
      gridTemplateColumns={"15rem 1fr"}
      bg={"gray.50"}
      minH='100vh'
      gap='1'
      fontWeight='bold'
      p={"2px"}
    >
      <GridItem area={"nav"} bg={"white"}>
        <NavSidebar />
      </GridItem>
      <GridItem area={"header"} mx={4} my={2}>
        <AppHeader />
      </GridItem>
      <GridItem area={"main"} p={4}>
        <main style={{ height: "100%" }}>
          <Outlet /> {/* Routed components will be rendered here */}
        </main>
      </GridItem>
    </Grid>
  );
}

export default App;

import { useEffect, useState } from "react";
import { ClientTaskBase } from "@oben-core-web/models/client-task-base";
import { ClientTaskBaseService } from "@oben-core-web/services/client-task-base-service";
import { ClientTaskType } from "@oben-core-web/models/client-task";

const useTaskBase = ({
  placeBasedCareProvId,
  taskTypes
}: {
  placeBasedCareProvId?: string;
  taskTypes?: ClientTaskType[];
}) => {
  const [loading, setLoading] = useState(false);
  const [taskBases, setTaskBases] = useState<ClientTaskBase[]>([]);
  const stringifiedTypes = taskTypes?.length ? JSON.stringify(taskTypes) : "";
  useEffect(() => {
    let loading = false;
    const fetchTaskBases = async () => {
      if (!loading) {
        const taskBaseService = new ClientTaskBaseService();
        setLoading(true);
        const taskBases = await taskBaseService.getAllClientTaskBases(
          placeBasedCareProvId ?? ""
        );
        setTaskBases(taskBases);
        setLoading(false);
      }
    };
    fetchTaskBases();
    return () => {
      loading = true;
    };
  }, [placeBasedCareProvId, stringifiedTypes]);
  return { taskBases, loading };
};

export default useTaskBase;

import { DocumentSnapshot } from "firebase/firestore";

import { CustomWorkingHoursService } from "../services/custom-working-hours-service";

/// CustomWorkingHours records are maintained in a subcollection on WebUser.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface ICustomWorkingHoursData {
  modelId: string;
  userId: string;
  start: Date | null;
  end: Date | null;
  isDayOff: boolean;
}

export class CustomWorkingHours {
  modelId: string;
  userId: string;
  start: Date | null;
  end: Date | null;
  isDayOff: boolean;
  constructor({
    modelId,
    userId,
    start,
    end,
    isDayOff
  }: ICustomWorkingHoursData) {
    this.modelId = modelId;
    this.userId = userId;
    this.start = start;
    this.end = end;
    this.isDayOff = isDayOff;
  }

  async updateDb(): Promise<void> {
    const db = new CustomWorkingHoursService();
    await db.updateCustomWorkingSlot(this);
  }

  // Factories
  //
  static fromFirestore(doc: DocumentSnapshot): CustomWorkingHours {
    const data = doc.data() as { [key: string]: any };
    return CustomWorkingHours.fromMap(doc.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): CustomWorkingHours {
    return new CustomWorkingHours({
      modelId: data["modelId"] ?? id, //  with subcollections we prefer to use the id that is stored within the document itself
      userId: data["userId"] ?? "",
      start: data["start"] ? new Date(data["start"]) : null,
      end: data["end"] ? new Date(data["end"]) : null,
      isDayOff: data["isDayOff"] ?? false
    });
  }

  toJson(): { [key: string]: any } {
    return {
      modelId: this.modelId, // with subcollections we must save ID within the Firestore document itself
      userId: this.userId,
      start: this.start?.toISOString() ?? null,
      end: this.end?.toISOString() ?? null,
      isDayOff: this.isDayOff
    };
  }
}

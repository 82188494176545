import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Text,
  Input,
  Heading,
  Flex,
  useToast,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Card
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours"; // Adjust the import path as needed
import { WebUser } from "@oben-core-web/models/web-user"; // Adjust the import path as needed
import { CustomWorkingHours } from "@oben-core-web/models/custom-working-hours"; // Adjust the import path as needed
import { CustomWorkingHoursService } from "@oben-core-web/services/custom-working-hours-service"; // Adjust the import path as needed

interface IUserProfileAdjustments {
  canEdit: boolean;
  user: WebUser;
}

interface ICustomWorkingHourData {
  id: string;
  date: string;
  start: string;
  end: string;
  isDayOff: boolean;
}

const UserProfileAdjustments = ({ canEdit, user }: IUserProfileAdjustments) => {
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState<CustomWorkingHours | null>(
    null
  );
  const {
    isOpen: isDialogOpen,
    onOpen: onDialogOpen,
    onClose: onDialogClose
  } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose
  } = useDisclosure();

  const { customWorkingHours, fetchCustomWorkingHours } =
    useCustomWorkingHours();
  // {
  //   userId: user?.uid ?? "",
  //   start: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
  //   end: DateTime.now()
  //     .plus({ years: 5 })
  //     .set({ hour: 0, minute: 0, second: 0 })
  //     .toJSDate()
  // }

  const workingHourAdjustments: ICustomWorkingHourData[] =
    customWorkingHours.map((cwh: CustomWorkingHours) => ({
      id: cwh.modelId,
      date: DateTime.fromJSDate(cwh.start!).toFormat("M/dd/yy"),
      start: DateTime.fromJSDate(cwh.start!).toFormat("h:mm a"),
      end: DateTime.fromJSDate(cwh.end!).toFormat("h:mm a"),
      isDayOff: cwh.isDayOff
    }));

  useEffect(() => {
    if (user) {
      fetchCustomWorkingHours({
        userId: user?.uid ?? "",
        start: DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
        end: DateTime.now()
          .plus({ years: 5 })
          .set({ hour: 0, minute: 0, second: 0 })
          .toJSDate()
      });
    }
  }, [user, fetchCustomWorkingHours]);

  const toggleDialog = useCallback(() => {
    if (isDialogOpen) {
      setSelectedItem(null);
    }
    onDialogOpen();
  }, [isDialogOpen, onDialogOpen]);

  const closeDeleteDialog = useCallback(() => {
    setSelectedItem(null);
    onDeleteDialogClose();
  }, [onDeleteDialogClose]);

  const deleteItemConfirm = useCallback(() => {
    if (!selectedItem) {
      console.log("No item was selected");
      return;
    }
    const customWorkingHourService = new CustomWorkingHoursService();
    customWorkingHourService
      .deleteCustomWorkingSlot(selectedItem)
      .then(() => {
        toast({ description: "Adjustment deleted", variant: "Success" });
        setSelectedItem(null);
      })
      .catch((e) => {
        console.log("Encountered error while deleting CustomWorkingHour: ", e);
        toast({ description: "Failed to delete adjustment", variant: "Error" });
      })
      .finally(() => {
        onDeleteDialogClose();
      });
  }, [selectedItem, onDeleteDialogClose, toast]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      date: "",
      start: "",
      end: "",
      isDayOff: false
    }
  });
  const editItem = useCallback(
    (item: ICustomWorkingHourData) => {
      const customWorkingHour = customWorkingHours.find(
        (cwh: CustomWorkingHours) => cwh.modelId === item.id
      );
      if (!customWorkingHour) {
        console.log("NO CUSTOM WORKING HOUR FOUND");
        return;
      }
      setSelectedItem(customWorkingHour);
      reset({
        date: DateTime.fromJSDate(customWorkingHour.start!).toFormat(
          "yyyy-MM-dd"
        ),
        start: DateTime.fromJSDate(customWorkingHour.start!).toFormat("H:mm"),
        end: DateTime.fromJSDate(customWorkingHour.end!).toFormat("H:mm"),
        isDayOff: customWorkingHour.isDayOff
      });
      onDialogOpen();
    },
    [customWorkingHours, onDialogOpen, reset]
  );

  const deleteItem = useCallback(
    (item: ICustomWorkingHourData) => {
      const customWorkingHour = customWorkingHours.find(
        (cwh: CustomWorkingHours) => cwh.modelId === item.id
      );
      if (!customWorkingHour) {
        console.log("NO CUSTOM WORKING HOUR FOUND");
        return;
      }
      setSelectedItem(customWorkingHour);
      onDeleteDialogOpen();
    },
    [customWorkingHours, onDeleteDialogOpen]
  );

  const onSubmit = async (data: any) => {
    // Handle form submission
    const userId = user.uid;
    const date = new Date(data.date);
    const [startHour, startMin] = data.start.split(":");
    const [endHour, endMin] = data.end.split(":");
    const start = DateTime.fromJSDate(date)
      .set({ hour: startHour ? startHour : 0, minute: startMin ? startMin : 0 })
      .toJSDate();
    const end = DateTime.fromJSDate(date)
      .set({ hour: endHour ? endHour : 0, minute: endMin ? endMin : 0 })
      .toJSDate();
    const customWorkingHourService = new CustomWorkingHoursService();
    const customWorkingHour = new CustomWorkingHours({
      modelId: selectedItem?.modelId ?? "",
      userId,
      start,
      end,
      isDayOff: data.isDayOff
    });
    if (selectedItem) {
      await customWorkingHourService
        .updateCustomWorkingSlot(customWorkingHour)
        .then(() => {
          toast({
            description: "Schedule adjustment updated!",
            status: "success"
          });
        })
        .catch((e) => {
          console.log("Edit schedule adjustment error:", e);
          toast({
            description: "Failed to update schedule adjustment",
            status: "error"
          });
        });
    } else {
      await customWorkingHourService
        .addCustomWorkingSlot(customWorkingHour)
        .then(() => {
          toast({
            description: "Schedule adjustment created!",
            status: "success"
          });
        })
        .catch((e) => {
          console.log("Create custom working hour error:", e);
          toast({
            description: "Failed to create schedule adjustment",
            status: "error"
          });
        });
    }
  };

  const handleCloseDialog = () => {
    reset();
    setSelectedItem(null);
    onDialogClose();
  };

  return (
    <Card width='full' mt={4} px={6} py={4}>
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        <Heading as='h3' fontSize={28}>
          Schedule Adjustments
        </Heading>
        {canEdit && (
          <Button
            // leftIcon={<AddIcon />}
            colorScheme='blue'
            onClick={toggleDialog}
            variant={"link"}
          >
            New Adjustment
          </Button>
        )}
      </HStack>

      <Flex justify='space-between' align='center' my={4}>
        <Text>
          Create schedule adjustments to modify your working hours on specific
          days.
        </Text>
      </Flex>
      {workingHourAdjustments.length > 0 && (
        <Table variant='simple' size='sm'>
          <Thead>
            <Tr color={"gray.500"}>
              <Th>Date</Th>
              <Th>Start</Th>
              <Th>End</Th>
              <Th>Day Off</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {workingHourAdjustments.map((item) => (
              <Tr key={item.id}>
                <Td>{item.date}</Td>
                <Td>{item.start !== item.end ? item.start : "-"}</Td>
                <Td>{item.start !== item.end ? item.end : "-"}</Td>
                <Td>
                  <Checkbox isChecked={item.isDayOff} isReadOnly />
                </Td>
                {canEdit && (
                  <Td>
                    <IconButton
                      aria-label='edit-icon'
                      icon={<EditIcon />}
                      size='sm'
                      mr={2}
                      onClick={() => editItem(item)}
                    />
                    <IconButton
                      aria-label='delete-icon'
                      icon={<DeleteIcon />}
                      size='sm'
                      onClick={() => deleteItem(item)}
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <Modal isOpen={isDialogOpen} onClose={handleCloseDialog}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Add Working Hour Adjustment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* WorkingHourAdjustmentsForm can be another component */}
              <VStack spacing={4} alignItems={"flex-start"}>
                <FormControl>
                  <FormLabel>Date</FormLabel>
                  <Controller
                    name='date'
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder='Date' type={"date"} />
                    )}
                  />
                </FormControl>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"full"}
                >
                  <FormControl>
                    <FormLabel>Start Time</FormLabel>
                    <Controller
                      name='start'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder='Start Time'
                          type={"time"}
                          w={"1/3"}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>End Time</FormLabel>
                    <Controller
                      name='end'
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder='End Time'
                          type={"time"}
                          w={"1/3"}
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel>Day Off</FormLabel>
                  <Controller
                    name='isDayOff'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value={JSON.stringify(field.value)}
                        isChecked={field.value}
                      >
                        {/* All-day */}
                      </Checkbox>
                    )}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' onClick={handleCloseDialog} mr={2}>
                Close
              </Button>
              <Button type='submit' colorScheme='blue'>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteDialogOpen} onClose={closeDeleteDialog}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this item?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={deleteItemConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default UserProfileAdjustments;

import { DocumentSnapshot } from "firebase/firestore";

// import { WebAvatar, WebAvatarExtension } from 'oben_images/oben_images'; // TODO: Uncomment this line after creating the WebAvatar class
import { WebUserService } from "../services/web-user-service";
import { UserType, UserTypeHelper } from "../constants/core-enums";
import { UserName } from "./user-name";
import { DefaultWorkingHours } from "./default-working-hours";

export interface IWebUserData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // web-specific fields
  // avatar: WebAvatar; // TODO: Uncomment this line after creating the WebAvatar class
  userType: UserType;
  placeBasedCareProvId: string | null;
  defaultWorkingHours: DefaultWorkingHours[];
}

export class WebUser {
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // avatar: WebAvatar; // TODO: Uncomment this line after creating the WebAvatar class
  userType: UserType;
  placeBasedCareProvId: string | null;
  defaultWorkingHours: DefaultWorkingHours[];

  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    // avatar, // TODO: Uncomment this line after creating the WebAvatar class
    userType,
    placeBasedCareProvId,
    defaultWorkingHours = []
  }: IWebUserData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the WebAvatar class
    this.userType = userType;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.defaultWorkingHours = defaultWorkingHours;
  }

  get isOnboardingComplete(): boolean {
    if (
      this.name.display == null ||
      this.name.first == null ||
      this.name.last == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  async updateDb(): Promise<void> {
    const db = new WebUserService();
    await db.updateWebUser(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): WebUser {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return WebUser.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): WebUser {
    const name: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string | null = data["phoneNumber"] ?? null;
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset: boolean = data["pwdNeedsReset"] ?? true;
    // const avatar: WebAvatar = WebAvatar.valueOf(data["avatar"] ?? "") ?? WebAvatar.zero; // TODO: Uncomment this line after creating the WebAvatar class
    const userType: UserType =
      UserTypeHelper.valueOf(data["userType"] ?? "") ?? UserType.Unknown;
    const placeBasedCareProvId: string | null = data["placeBasedCareProvId"] ?? null;
    const defaultWorkingHours: DefaultWorkingHours[] =
      data["defaultWorkingHours"] === null ||
      data["defaultWorkingHours"].length === 0
        ? []
        : data["defaultWorkingHours"].map((element: any) => DefaultWorkingHours.fromMap(element ?? {}));
    return new WebUser({
      uid: uid,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      // avatar: avatar, // TODO: Uncomment this line after creating the WebAvatar class
      userType: userType,
      placeBasedCareProvId: placeBasedCareProvId,
      defaultWorkingHours: defaultWorkingHours
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "uid": uid, // not necessary to save UID (it is already part of the Firestore document)
      "name": this.name.toJson(),
      "email": this.email,
      "phoneNumber": this.phoneNumber,
      "enabled": this.enabled,
      "pwdNeedsReset": this.pwdNeedsReset,
      // "avatar": this.avatar, // TODO: Uncomment this line after creating the WebAvatar class
      "userType": this.userType,
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "defaultWorkingHours": this.defaultWorkingHours.map((element) => element.toJson()),
    };
  }
}
